//Mapping display names to validation property names
export enum SpecialPricingExpirationExcelColumnHeaders {
    controlNumber = "Control Number",
    customerNumber = "Customer Number",
    customerShipto = "Customer Ship-to Number",
    customerName = "Customer Name",
    customerAddress = "Customer Address",
    productLine = "Product Line Number",
    productLineDescription = "Product Line Description",
    productLineCategory = "Product Category",
    productLineCategoryDescription = "Product Category Description",
    contractNumber = "Contract Number",
    contractName = "Contract Name",
    itemNumber = "Item Number",
    itemDescription = "Item Description",
    manufacturerNumber = "Manufacturer Number",
    priceType = "Price Type",
    priceAmount = "Pricing Amount",
    priceUom = "Pricing Unit of Measure",
    floatPercent = "Float Percentage",
    deliveryEffectiveDate = "Delivery Effective Date",
    deliveryExpirationDate = "Delivery Expiration Date",
    employee = "Employee Responsible",
    jobType = "Job Type",
    supplierResponsible = "Supplier Responsible (Y/N)",
    action = "Extend/Delete/Cancel/New",
    extendDate = "Extend Date",
    comments = "Comments",
    newBeginDate = "New Start Date",
    newEndDate = "New End Date",
    newPriceUom = "New Pricing Unit of Measure",
    newPriceAmount = "New Pricing Amount",
    newContractNumber = "New Contract Number",
    newContractName = "New Contract Name",
    newComments = "New Terms Comments"
}

//Mapping raw excel property names to validation model property names
export enum SpecialPricingExpirationTemplateErrorKeys {
    controlNumber = "CONTROLNUMBER",
    customerNumber = "CUSTOMERNUMBER",
    customerShipto = "CUSTOMERSHIPTO",
    customerName = "CUSTOMERNAME",
    customerAddress = "CUSTOMERADDRESS",
    productLine = "PRODUCTLINENUMBER",
    productLineDescription = "PRODUCTLINEDESCRIPTION",
    productLineCategory = "PRODUCTCATEGORY",
    productLineCategoryDescription = "CATEGORYDESCRIPTION",
    contractNumber = "CONTRACTNUMBER",
    contractName = "CONTRACTNAME",
    itemNumber = "ITEMNUMBER",
    itemDescription = "ITEMDESCRIPTION",
    manufacturerNumber = "MANUFACTURERSNUMBER",
    priceType = "PRICETYPE",
    priceAmount = "PRICINGAMOUNT",
    priceUom = "PRICINGUOM",
    floatPercent = "FLOATPERCENT",
    deliveryEffectiveDate = "DELIVERYEFFECTIVEDATE",
    deliveryExpirationDate = "DELIVERYEXPIRATIONDATE",
    employee = "EMPRESPONSIBLEPRICING",
    jobType = "JOBTYPE",
    supplierResponsible = "SUPPLIERRESPONSIBLEY/N",
    action = "EXTEND/DELETE/CANCEL/NEW",
    extendDate = "EXTENDDATE",
    comments = "COMMENTS",
    newPriceAmount = "NEWPRICINGAMOUNT",
    newPriceUom = "NEWPRICEUOM",
    newContractNumber = "NEWCONTRACTNUMBER",
    newContractName = "NEWCONTRACTNAME",
    newBeginDate = "NEWSTARTDATE",
    newEndDate = "NEWENDDATE",
    newComments = "COMMENTS_1"
}

//Mapping raw excel property names to request/response property names
export enum SpecialPricingExpirationTemplateApiResponseKeys {
    CONTROLNUMBER = "CONTROLNUMBER",
    CUSTOMERNUMBER = "CUSTOMERNUMBER",
    CUSTOMERSHIPTONUMBER = "CUSTOMERSHIPTO",
    CUSTOMERNAME = "CUSTOMERNAME",
    CUSTOMERADDRESS = "CUSTOMERADDRESS",
    PRODUCTLINE = "PRODUCTLINENUMBER",
    PRODUCTLINEDESCRIPTION = "PRODUCTLINEDESCRIPTION",
    PRODUCTLINECATEGORY = "PRODUCTCATEGORY",
    PRODUCTLINECATEGORYDESCRIPTION = "CATEGORYDESCRIPTION",
    EMPLOYEENUMBER = "EMPRESPONSIBLEPRICING",
    JOBTYPE = "JOBTYPE",
    SUPPLIERINDICATOR = "SUPPLIERRESPONSIBLEY/N",
    CONTRACTNUMBER = "CONTRACTNUMBER",
    CONTRACTNAME = "CONTRACTNAME",
    ITEMNUMBER = "ITEMNUMBER",
    ITEMDESCRIPTION = "ITEMDESCRIPTION",
    MANUFACTURERNUMBER = "MANUFACTURERSNUMBER",
    PRICETYPE = "PRICETYPE",
    PRICEAMOUNT = "PRICINGAMOUNT",
    PRICEUNITOFMEASURE = "PRICINGUOM",
    FLOATPERCENT = "FLOATPERCENT",
    DELIVERYEFFECTIVEDATE = "DELIVERYEFFECTIVEDATE",
    DELIVERYEXPIRATIONDATE = "DELIVERYEXPIRATIONDATE",
    ACTION = "EXTEND/DELETE/CANCEL/NEW",
    EXTENDDATE = "EXTENDDATE",
    COMMENTS = "COMMENTS",
    NEWPRICEAMOUNT = "NEWPRICINGAMOUNT",
    NEWPRICEUNITOFMEASURE = "NEWPRICEUOM",
    NEWCONTRACTNUMBER = "NEWCONTRACTNUMBER",
    NEWCONTRACTNAME = "NEWCONTRACTNAME",
    NEWSTARTDATE = "NEWSTARTDATE",
    NEWENDDATE = "NEWENDDATE",
    NEWCOMMENTS = "COMMENTS_1"
}
