export enum SpecialPricingExpirationTemplateSearchTypes {
    CustomerId = "CustomerId",
    CustomerName = "CustomerName",
    ItemNumber = "ItemNumber",
    ItemName = "ItemName",
    ProductLineNumber = "ProductLineNumber",
    ProductLineName = "ProductLineName",
    ProductLineCategoryNumber = "ProductLineCategoryNumber",
    ContractNumber = "ContractNumber",
    ContractName = "ContractName",
    TemplateId = "TemplateId",
    Action = "Action"
};
