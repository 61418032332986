import { ExcelTemplateTypes, SpecialPricingExpirationTemplateSearchTypes } from "enums";
import { useInOutTransition } from "hooks";
import SearchRadioInput from "./SearchRadioInput";

interface Props {
    searchType: string,
    updateHandler: (x: SpecialPricingExpirationTemplateSearchTypes) => void,
    selectedTemplateType: ExcelTemplateTypes
}

export default function SpecialPricingExpirationTemplateSearchOptions(props: Props) {
    const { searchType, updateHandler, selectedTemplateType } = props,
          shouldRender = selectedTemplateType === ExcelTemplateTypes.Expiration,
          { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 0, 275);
    return (isNotTransitionedOut && 
        <>
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerId" 
                              labelText="Customer Id"
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.CustomerId}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.CustomerId)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerName" 
                              labelText="Customer Name" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.CustomerName}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.CustomerName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineNumber" 
                              labelText="Product Line Number" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ProductLineNumber}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ProductLineNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineName" 
                              labelText="Product Line Name" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ProductLineName}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ProductLineName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineCategoryNumber" 
                              labelText="Product Category Number" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ProductLineCategoryNumber}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ProductLineCategoryNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="itemNumber" 
                              labelText="Item Number" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ItemNumber}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ItemNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="itemName" 
                              labelText="Item Description" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ItemName}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ItemName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractNumber" 
                              labelText="Contract Number" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ContractNumber}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ContractNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractName" 
                              labelText="Contract Name" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.ContractName}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.ContractName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="action" 
                              labelText="Action Type" 
                              checkedCondition={searchType === SpecialPricingExpirationTemplateSearchTypes.Action}
                              onClick={() => updateHandler(SpecialPricingExpirationTemplateSearchTypes.Action)} />
            
        </>
    );
}
