import { ClientValidationResult, DateDetails, 
         DateMetadata, 
         ExcelPropertyInfo, 
         ExcelViewerErrorData, 
         NewExpirationTerms, 
         PricingExcelApiResult, 
         SpecialPricingExpirationApiValidationRequestData, 
         SpecialPricingExpirationExcelApiValidationRequest, 
         SpecialPricingExpirationExcelApiValidationResponse, 
         SpecialPricingExpirationExcelDataRow, 
         SpecialPricingExpirationExcelTemplate, 
         ValidationService } from "types";
import { SpecialPricingExpirationExcelColumnHeaders, 
         SpecialPricingExpirationTemplateErrorKeys, 
         YesOrNo } from "enums";
import { useExcelValidationHelpers, 
         useDateCheckerService, 
         useSecurityService } from "services";
import { commonRegex, dateFormatter } from "utils";
import { ClientValidationError, ClientValidationErrorType } from "errors";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export function useSpecialPricingExpirationTemplateValidationService(id: string, fileName: string, changeNotes: string, postValidationCallback?: (args: any[]) => void): ValidationService {
    let excelViewerErrorData: ExcelViewerErrorData[] = [];

    const { getAuthHeader, getUserEmail } = useSecurityService();

    const { checkValidDay, 
            checkValidMonth, 
            checkValidYear,
            checkIsPastDate } = useDateCheckerService();
    
    const { checkRequiredField, 
            checkExcelHeaders,
            standardizePropertyNames,
            trimValuesLeadingAndTrailingWhitespace,
            tryParseInt: tryParseIntGeneric } = useExcelValidationHelpers();
            
    const tryParseInt = (prop: ExcelPropertyInfo) => tryParseIntGeneric(SpecialPricingExpirationExcelColumnHeaders, prop);

    const apiUrl = process.env.REACT_APP_SALES_PRICINGIMPORT_SPECIAL_PRICING_EXPIRATION_VALIDATION_API,
          maxPricingDecimalPlaces = 4,
          regex = { dateFormat: commonRegex.dateFormats.mmddyyyy,
                    aboveMaxPrecision: commonRegex.maxDecimalPrecision(maxPricingDecimalPlaces),
                    hasNonNumeric: commonRegex.hasNonNumeric,
                    hasNonPositiveNumeric: commonRegex.hasNonPositiveNumeric,
                    allNines: commonRegex.allNines };
                      
    //Sends a POST request containing uploaded and client-side validated excel data to Sales.PricingImport
    const postToValidationApi = async (clientValidatedExcel: SpecialPricingExpirationExcelDataRow[]) => {
        const outboundModel: SpecialPricingExpirationExcelApiValidationRequest = await convertToRequestModel(clientValidatedExcel);
        try {
            const config: AxiosRequestConfig = { headers: await getAuthHeader() },
                  response = await axios.post(`${apiUrl}`, outboundModel, config),
                  status = response.status;
            return { response: response as SpecialPricingExpirationExcelApiValidationResponse, 
                     postError: "",
                     statusCode: status } as PricingExcelApiResult;
        } catch(ex: any) {
            const error = ex as AxiosError,
                  status = error.response?.status ?? 0;
            return { response: error.response as SpecialPricingExpirationExcelApiValidationResponse, 
                     postError: error.message ? error.message : "Error posting to validation API.",
                     statusCode: status } as PricingExcelApiResult;
        }
    };

    const doClientSideValidation = async (excel: SpecialPricingExpirationExcelTemplate[] | null, skipNumHeaders: number) => {
        let errText: string = "";
        const errorList: ExcelViewerErrorData[] = [],
              panelErrors: string[] = [],
              validFlags = Object.values<string>(YesOrNo);

        excelViewerErrorData = [];
        const extraHeaderValidation = () => {
            //Checking for data in a few fields of the first data row to ensure that this isn't a blank template
            if (!excel) return;
            if (skipNumHeaders < 1) {
                if ( !( excel[0]["CUSTOMERNUMBER"] 
                     || excel[0]["CONTROLNUMBER"] 
                     || excel[0]["PRICINGAMOUNT"] 
                     || excel[0]["NEWPRICINGAMOUNT"] 
                     || excel[0]["EXTEND/DELETE/CANCEL/NEW"]
                     || excel[0]["EMPRESPONSIBLEPRICING"]
                     || excel[0]["SUPPLIERRESPONSIBLEY/N"]
                     || excel[0]["JOBTYPE"] ) )
                {
                    throw new ClientValidationError(ClientValidationErrorType.NoData, 
                                                    "Excel file does not appear to contain any valid data rows.", 
                                                    null);
                }
            }
        };
        checkExcelHeaders(excel, skipNumHeaders, extraHeaderValidation);

        //Null suppression because the above call to checkExcelHeaders will 
        //throw a ClientValidationError on null or empty excel data, so there's no need for another check.
        const excelData = convertToValidationModel(excel!, skipNumHeaders);

        //Loop through rows.  
        for (const [index, row] of excelData.entries()) {
            //1 to skip the header (keys) row, 1 to offset zero-indexing
            const rowNumber = index + 2 + skipNumHeaders;

            await doDateValidation(errorList, row, rowNumber);

            for (const prop of Object.getOwnPropertyNames(row)) {
                const thisCellValue = (`${row[prop as keyof SpecialPricingExpirationExcelDataRow] ?? ""}`).trim(),
                      propNameKey = prop as keyof typeof SpecialPricingExpirationExcelColumnHeaders,
                      propColumnName = `column "${SpecialPricingExpirationExcelColumnHeaders[propNameKey]}"`,
                      errorKey = prop as keyof typeof SpecialPricingExpirationTemplateErrorKeys,
                      thisPropInfo = { propName: propNameKey,
                                       value: thisCellValue,
                                       dataRow: rowNumber,
                                       errorKey: errorKey,
                                       log: errorList } as ExcelPropertyInfo;
                switch (prop) {
                    case "supplierResponsible":
                        if (!thisCellValue) {
                            row[prop] = YesOrNo.No;
                            break;
                        }
                        else {
                            const flagValue = (`${thisCellValue}`).toUpperCase();
                            if (validFlags.includes(flagValue)) {
                                row[prop] = flagValue;
                            } else {
                                errText = `Invalid value "${thisCellValue}" for ${propColumnName}, this field can only include ` + 
                                            `one of the following values: ${validFlags.join(", ")}`;
                                errorList.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys[errorKey], [errText]));
                            }
                        }
                        break;
                    case "customerNumber":
                    case "customerShipto":
                    case "controlNumber": 
                        if (!checkIfRequiredFieldIsNotEmpty(thisPropInfo)) {
                            break;
                        } else if (thisCellValue && regex.hasNonPositiveNumeric.test(thisCellValue)) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, ` +
                                      `cannot contain non-numeric characters.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, propNameKey, [errText]));
                        } else if (thisCellValue !== "" && parseInt(thisCellValue) < 0) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, cannot be a negative number.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys[errorKey], [errText]));
                        }
                        break;
                    case "action":
                            checkIfRequiredFieldIsNotEmpty(thisPropInfo);
                        break;
                    case "itemNumber":
                    case "productLineNumber":
                    case "productLineCategory":
                        if (thisCellValue !== "" && parseInt(thisCellValue) < 0) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, cannot be a negative number.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys[errorKey], [errText]));
                        } else if (thisCellValue && regex.hasNonPositiveNumeric.test(thisCellValue)) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, ` +
                                      `cannot contain non-numeric characters.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, propNameKey, [errText]));
                        } 
                        break;
                    case "floatPercent":
                    case "newPriceAmount":
                    case "priceAmount":
                        if (thisCellValue && regex.hasNonNumeric.test(thisCellValue)) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, ` +
                                      `cannot contain non-numeric characters.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, propNameKey, [errText]));
                        } else if (regex.aboveMaxPrecision.test(thisCellValue)) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, this field can have no more ` + 
                                      `than ${maxPricingDecimalPlaces} decimal places.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys[errorKey], [errText]));
                        }
                        break;
                    case "employee":
                    case "jobType":
                        if (thisCellValue !== "" && parseInt(thisCellValue) < 0) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, cannot be a negative number.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys[errorKey], [errText]));
                        }
                        break;
                }
            }
        }

        //When we're done looping through rows
        if (postValidationCallback) 
            postValidationCallback([...excelViewerErrorData, ...errorList]);

        errorList.forEach(x => panelErrors.push(`Data row ${x.row} - ${x.info.join(" | ")}`));
        
        return { requestData: excelData, 
                 errorList: panelErrors, 
                 errorCount: panelErrors.length } as ClientValidationResult;
    };

    //Strips spaces and lower case letters out of Excel template property names
    const standardizeExcelPropertyNameFormat = (rawExcel: any[]) => standardizePropertyNames<SpecialPricingExpirationExcelTemplate>(rawExcel);

    //Logs error on missing required field 
    const checkIfRequiredFieldIsNotEmpty = (props: ExcelPropertyInfo) => checkRequiredField(SpecialPricingExpirationExcelColumnHeaders, props);
    
    //Checks for invalid date formats, past dates, and end dates before begin dates/extension date
    const doDateValidation = async (errors: ExcelViewerErrorData[], model: SpecialPricingExpirationExcelDataRow, rowNumber: number) => {
        const format: RegExp = regex.dateFormat,
              beginErrors: ExcelViewerErrorData[] = [],
              endErrors: ExcelViewerErrorData[] = [],
              extendErrors: ExcelViewerErrorData[] = [],
              newBeginErrors: ExcelViewerErrorData[] = [],
              newEndErrors: ExcelViewerErrorData[] = [],

              begin = model.deliveryEffectiveDate?.toString().match(format),
              end = model.deliveryExpirationDate?.toString().match(format),
              extend = model.extendDate?.toString().match(format),
              newBegin = model.newBeginDate?.toString().match(format),
              newEnd = model.newEndDate?.toString().match(format),

              bothDeliveryColumns = `${SpecialPricingExpirationTemplateErrorKeys.deliveryEffectiveDate}, ${SpecialPricingExpirationTemplateErrorKeys.deliveryExpirationDate}`,
              bothNewColumns = `${SpecialPricingExpirationTemplateErrorKeys.newBeginDate}, ${SpecialPricingExpirationTemplateErrorKeys.newEndDate}`,
              oldEndNewExtendColumns = `${SpecialPricingExpirationTemplateErrorKeys.extendDate}, ${SpecialPricingExpirationTemplateErrorKeys.deliveryExpirationDate}`;

        const beginDate = { year: parseInt(begin?.groups?.year ?? ""),
                            month: parseInt(begin?.groups?.month ?? ""),
                            day: parseInt(begin?.groups?.day ?? "") } as DateDetails,
              endDate = { year: parseInt(end?.groups?.year ?? ""),
                          month: parseInt(end?.groups?.month ?? ""),
                          day: parseInt(end?.groups?.day ?? "") } as DateDetails,
              extendDate = { year: parseInt(extend?.groups?.year ?? ""),
                             month: parseInt(extend?.groups?.month ?? ""),
                             day: parseInt(extend?.groups?.day ?? "") } as DateDetails,
              newEndDate = { year: parseInt(newEnd?.groups?.year ?? ""),
                             month: parseInt(newEnd?.groups?.month ?? ""),
                             day: parseInt(newBegin?.groups?.day ?? "") } as DateDetails,
              newBeginDate = { year: parseInt(newBegin?.groups?.year ?? ""),
                               month: parseInt(newBegin?.groups?.month ?? ""),
                               day: parseInt(newBegin?.groups?.day ?? "") } as DateDetails,

              beginDateMetadata = { fullDateString: model.deliveryEffectiveDate,
                                    date: beginDate,
                                    dateType: SpecialPricingExpirationTemplateErrorKeys.deliveryEffectiveDate,
                                    dateColumnName: SpecialPricingExpirationTemplateErrorKeys.deliveryEffectiveDate,
                                    rowNumber: rowNumber } as DateMetadata,

              endDateMetadata = { fullDateString: model.deliveryExpirationDate,
                                  date: endDate,
                                  dateType: SpecialPricingExpirationTemplateErrorKeys.deliveryExpirationDate,
                                  dateColumnName: SpecialPricingExpirationTemplateErrorKeys.deliveryExpirationDate,
                                  rowNumber: rowNumber } as DateMetadata,
                                  
              extendDateMetadata = { fullDateString: model.extendDate,
                                     date: extendDate,
                                     dateType: SpecialPricingExpirationTemplateErrorKeys.extendDate,
                                     dateColumnName: SpecialPricingExpirationTemplateErrorKeys.extendDate,
                                     rowNumber: rowNumber } as DateMetadata,
                                  
              newBeginDateMetadata = { fullDateString: model.newBeginDate,
                                       date: newBeginDate,
                                       dateType: SpecialPricingExpirationTemplateErrorKeys.newBeginDate,
                                       dateColumnName: SpecialPricingExpirationTemplateErrorKeys.newBeginDate,
                                       rowNumber: rowNumber } as DateMetadata,
                                  
              newEndDateMetadata = { fullDateString: model.newEndDate,
                                     date: newEndDate,
                                     dateType: SpecialPricingExpirationTemplateErrorKeys.newEndDate,
                                     dateColumnName: SpecialPricingExpirationTemplateErrorKeys.newEndDate,
                                     rowNumber: rowNumber } as DateMetadata;

        const flags = { sameYear: beginDate.year === endDate.year,
                        sameMonth: (beginDate.year === endDate.year && beginDate.month === endDate.month),
                        invalidMonth: false,
                        invalidYear: false,
                        invalidDay: false,
                        badFormat: false,
                        badBeginDate: false,
                        badEndDate: false,
                        badExtendDate: false,
                        badNewBeginDate: false,
                        badNewEndDate: false,
                        allNinesEndDate: false,
                        allNinesExtendDate: false,
                        allNinesNewEndDate: false };
        
        let errText: string = "";

        //deliveryEffectiveDate checks
        if (model.deliveryEffectiveDate) {
            flags.invalidYear = !checkValidYear(beginDateMetadata, beginErrors);
            flags.invalidMonth = !checkValidMonth(beginDateMetadata, beginErrors);
            flags.invalidDay = !checkValidDay(beginDateMetadata, beginErrors, flags.invalidMonth, flags.invalidYear);

            const badDateFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

            flags.badFormat = badDateFormat;
            flags.badBeginDate = badDateFormat;
        }

        //deliveryExpirationDate checks
        if (model.deliveryExpirationDate) {
            flags.allNinesEndDate = regex.allNines.test(model.deliveryExpirationDate);
            if (!flags.allNinesEndDate) {
                checkIsPastDate(endDateMetadata, endErrors);
                flags.invalidYear = !checkValidYear(endDateMetadata, endErrors);
                flags.invalidMonth = !checkValidMonth(endDateMetadata, endErrors);
                flags.invalidDay = !checkValidDay(endDateMetadata, endErrors, flags.invalidMonth, flags.invalidYear);
                
                const badDateFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

                flags.badEndDate = badDateFormat;
                flags.badFormat = flags.badFormat || badDateFormat;
                
                //Date range checks
                if( (flags.sameYear && beginDate.month > endDate.month)
                    || (flags.sameMonth && beginDate.day > endDate.day)
                    || (endDate.year < beginDate.year) ) {
                    errText = `Invalid date range of "${model.deliveryEffectiveDate}" - "${model.deliveryExpirationDate}", ` + 
                              `reason: expiration date is before effective date.`
                    errors.push(new ExcelViewerErrorData(rowNumber, bothDeliveryColumns, [errText]));
                }
            }
        }


        //extendDate checks
        if (model.extendDate) {
            flags.allNinesExtendDate = regex.allNines.test(model.extendDate);
            //Check if we even can extend
            if (flags.allNinesEndDate) {
                errText = `Cannot extend special pricing that does not expire`;
                errors.push(new ExcelViewerErrorData(rowNumber, oldEndNewExtendColumns, [errText]));
            }
            else if (!flags.allNinesExtendDate) {
                checkIsPastDate(extendDateMetadata, extendErrors);
                flags.sameYear = extendDate.year === endDate.year;
                flags.sameMonth = flags.sameYear && extendDate.month === endDate.month;
                flags.invalidYear = !checkValidYear(extendDateMetadata, extendErrors);
                flags.invalidMonth = !checkValidMonth(extendDateMetadata, extendErrors);
                flags.invalidDay = !checkValidDay(extendDateMetadata, extendErrors, flags.invalidMonth, flags.invalidYear);

                const badDateFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

                flags.badExtendDate = badDateFormat;
                flags.badFormat = flags.badFormat || badDateFormat;
                
                //Date range checks
                if( (flags.sameYear && endDate.month > extendDate.month)
                    || (flags.sameMonth && endDate.day > extendDate.day)
                    || (extendDate.year < endDate.year) ) {
                    errText = `Invalid extend date of "${model.extendDate}", ` + 
                              `reason: extend date must be after expiration date of "${model.deliveryExpirationDate}".`
                    errors.push(new ExcelViewerErrorData(rowNumber, oldEndNewExtendColumns, [errText]));
                }
            }
        }

        //newBeginDate checks
        if (model.newBeginDate) {
            checkIsPastDate(newBeginDateMetadata, newBeginErrors);
            flags.invalidYear = !checkValidYear(newBeginDateMetadata, newBeginErrors);
            flags.invalidMonth = !checkValidMonth(newBeginDateMetadata, newBeginErrors);
            flags.invalidDay = !checkValidDay(newBeginDateMetadata, newBeginErrors, flags.invalidMonth, flags.invalidYear);

            const badDateFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

            flags.badNewBeginDate = badDateFormat;
            flags.badFormat = flags.badFormat || badDateFormat;
        }

        //newEndDate checks
        if (model.newEndDate) {
            flags.allNinesNewEndDate = regex.allNines.test(model.newEndDate);
            if (!flags.allNinesNewEndDate) {
                checkIsPastDate(newEndDateMetadata, newEndErrors);
                flags.sameYear = newEndDate.year === newBeginDate.year;
                flags.sameMonth = flags.sameYear && newEndDate.month === newBeginDate.month;
                flags.invalidYear = !checkValidYear(newEndDateMetadata, newEndErrors);
                flags.invalidMonth = !checkValidMonth(newEndDateMetadata, newEndErrors);
                flags.invalidDay = !checkValidDay(newEndDateMetadata, newEndErrors, flags.invalidMonth, flags.invalidYear);
                
                const badDateFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

                flags.badNewEndDate = badDateFormat;
                flags.badFormat = flags.badFormat || badDateFormat;
                
                //Date range checks
                if( (flags.sameYear && newBeginDate.month > newEndDate.month)
                    || (flags.sameMonth && newBeginDate.day > newEndDate.day)
                    || (newEndDate.year < newBeginDate.year) ) {
                    errText = `Invalid date range of "${model.newBeginDate}" - "${model.newEndDate}", ` + 
                              `reason: new end date is before new start date.`
                    errors.push(new ExcelViewerErrorData(rowNumber, bothNewColumns, [errText]));
                }
            }
        }

        if (flags.badBeginDate) {
            errText = "Delivery effective date appears not to be in correct format.";
            errors.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys.deliveryEffectiveDate, [errText]));
        } else {
            //These errors are only valuable if begin date format is correct
            errors.push(...beginErrors);
        }

        if (flags.badEndDate) {
            errText = 'Delivery expiration date appears not to be in correct format.';
            errors.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys.deliveryExpirationDate, [errText]));
        } else {
            //These errors are only valuable if end date format is correct
            errors.push(...endErrors);
        }
        
        if (flags.badExtendDate) {
            errText = 'Extend date appears not to be in correct format.';
            errors.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys.extendDate, [errText]));
        } else {
            //These errors are only valuable if extend date format is correct
            errors.push(...extendErrors);
        }
        
        if (flags.badNewBeginDate) {
            errText = 'Extend date appears not to be in correct format.';
            errors.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys.newBeginDate, [errText]));
        } else {
            //These errors are only valuable if new begin date format is correct
            errors.push(...newBeginErrors);
        }
        
        if (flags.badNewEndDate) {
            errText = 'Extend date appears not to be in correct format.';
            errors.push(new ExcelViewerErrorData(rowNumber, SpecialPricingExpirationTemplateErrorKeys.newEndDate, [errText]));
        } else {
            //These errors are only valuable if new end date format is correct
            errors.push(...newEndErrors);
        }

        if (flags.badFormat) {
            errText = `Ensure that all dates are in the correct format: MMDDYYYY`;
            errors.push(new ExcelViewerErrorData(rowNumber, "", [errText]));
        }

        //Because we're accepting MMDDYYYY values for months 1-9 even without a leading zero, 
        //we need to standardize the format for the validation API
        if (model.deliveryEffectiveDate) 
            model.deliveryEffectiveDate = dateFormatter.MMDDYYYY(beginDate);

        if (model.deliveryExpirationDate && !flags.allNinesEndDate) 
            model.deliveryExpirationDate = dateFormatter.MMDDYYYY(endDate);

        if (model.extendDate && !flags.allNinesExtendDate) 
            model.extendDate = dateFormatter.MMDDYYYY(extendDate);

        if (model.newBeginDate) 
            model.newBeginDate = dateFormatter.MMDDYYYY(newBeginDate);

        if (model.newEndDate && !flags.allNinesNewEndDate) 
            model.newEndDate = dateFormatter.MMDDYYYY(newEndDate);
    };

    //Model conversions
    const convertToValidationModel = (rawExcelTemplate: SpecialPricingExpirationExcelTemplate[], skipNumHeaders: number): SpecialPricingExpirationExcelDataRow[] => {
        let validationModel: SpecialPricingExpirationExcelDataRow[] = [];
        const excelTemplate = trimValuesLeadingAndTrailingWhitespace<SpecialPricingExpirationExcelTemplate>(rawExcelTemplate);

        for (let i = skipNumHeaders; i < excelTemplate.length; i++) {
            const fieldIsEmpty = (prop: ExcelPropertyInfo) => (prop.value?.toString().trim().length ?? 0) === 0,
                    tryParseIfNotNull = (prop: ExcelPropertyInfo) => (fieldIsEmpty(prop) ? null : tryParseInt(prop)),
                    rowData = {} as SpecialPricingExpirationExcelDataRow,
                    excelData = excelTemplate[i],
                    propInfo = { propName: "",
                                 value: null,
                                 dataRow: i + 2,
                                 log: [] } as ExcelPropertyInfo;

            for (const property of Object.getOwnPropertyNames(excelData)) {
                const key = property as keyof typeof excelData;
                if (excelData[key] && typeof(excelData[key] === 'string')) {
                    excelData[key] = (`${excelData[key]}`).trim();
                }
            }

            //Non-integer fields

            rowData.customerName = excelData.CUSTOMERNAME ?? "";
            rowData.customerAddress = excelData.CUSTOMERADDRESS ?? "";

            rowData.deliveryEffectiveDate = excelData.DELIVERYEFFECTIVEDATE ?? "";
            rowData.deliveryExpirationDate = excelData.DELIVERYEXPIRATIONDATE ?? "";
            rowData.extendDate = excelData.EXTENDDATE ?? "";
            rowData.newBeginDate = excelData.NEWSTARTDATE ?? "";
            rowData.newEndDate = excelData.NEWENDDATE ?? "";

            rowData.itemDescription = excelData.ITEMDESCRIPTION ?? "";
            rowData.productLineDescription = excelData.PRODUCTLINEDESCRIPTION ?? "";
            rowData.productLineCategoryDescription = excelData.CATEGORYDESCRIPTION ?? "";
            rowData.manufacturerNumber = excelData.MANUFACTURERSNUMBER ?? "";

            rowData.supplierResponsible = excelData["SUPPLIERRESPONSIBLEY/N"] ?? "";
            rowData.action = excelData["EXTEND/DELETE/CANCEL/NEW"] ?? "";

            rowData.contractNumber = excelData.CONTRACTNUMBER ?? "";
            rowData.contractName = excelData.CONTRACTNAME ?? "";
            rowData.newContractName = excelData.NEWCONTRACTNAME ?? "";
            rowData.newContractNumber = excelData.NEWCONTRACTNUMBER ?? "";

            rowData.comments = excelData.COMMENTS ?? "";
            rowData.newComments = excelData.COMMENTS_1 ?? "";

            rowData.priceUom = excelData.PRICINGUOM ?? "";
            rowData.newPriceUom = excelData.NEWPRICEUOM ?? "";
            rowData.floatPercent = excelData.FLOATPERCENT ?? "";
            rowData.priceType = excelData.PRICETYPE ?? "";

            //Defaulting these to zero seemed like maybe a bad idea
            rowData.priceAmount = excelData.PRICINGAMOUNT;
            rowData.newPriceAmount = excelData.NEWPRICINGAMOUNT;

            //Integer fields

            //Control number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.controlNumber;
            propInfo.value = excelData.CONTROLNUMBER;
            rowData.controlNumber = tryParseIfNotNull(propInfo);

            //Customer number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.customerNumber;
            propInfo.value = excelData.CUSTOMERNUMBER;
            rowData.customerNumber = tryParseIfNotNull(propInfo);

            //Customer shipto
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.customerShipto;
            propInfo.value = excelData.CUSTOMERSHIPTO;
            rowData.customerShipto = tryParseIfNotNull(propInfo);

            //Item number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.itemNumber;
            propInfo.value = excelData.ITEMNUMBER;
            rowData.itemNumber = tryParseIfNotNull(propInfo);

            //Product line number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.productLine;
            propInfo.value = excelData.PRODUCTLINENUMBER;
            rowData.productLine = tryParseIfNotNull(propInfo);

            //Product category number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.productLineCategory;
            propInfo.value = excelData.PRODUCTCATEGORY;
            rowData.productLineCategory = tryParseIfNotNull(propInfo);

            //Employee number
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.employee;
            propInfo.value = excelData.EMPRESPONSIBLEPRICING;
            rowData.employee = tryParseIfNotNull(propInfo);

            //Job type
            propInfo.propName = SpecialPricingExpirationTemplateErrorKeys.jobType;
            propInfo.value = excelData.JOBTYPE;
            rowData.jobType = tryParseIfNotNull(propInfo);

            if (propInfo.log && propInfo.log.length > 0) {
                excelViewerErrorData.push(...propInfo.log);
            }

            validationModel.push(rowData);
        }
        return validationModel;
    };
    
    //Client validation model => validation API request model
    const convertToRequestModel = (validatedExcel: SpecialPricingExpirationExcelDataRow[]) => {
        const requestTemplate = validatedExcel.map(row => 
            { 
                const newTermsFields = Object.entries(row)
                                             .filter(([key, value]) => key.startsWith("new") && value),
                      hasNewTerms = newTermsFields && newTermsFields.length > 0;

                return { ControlNumber: `${row.controlNumber}`,
                         CustomerNumber: row.customerNumber ? row.customerNumber : 0,
                         CustomerShiptoNumber: row.customerShipto ? row.customerShipto : 0,
                         CustomerName: `${row.customerName}`,
                         CustomerAddress: `${row.customerAddress}`,

                         ContractNumber: `${row.contractNumber}`,
                         ContractName: `${row.contractName}`,
                         Comments: `${row.comments}`,
                         
                         ProductLine: row.productLine ? row.productLine : 0,
                         ProductLineDescription: `${row.productLineDescription}`,
                         ProductLineCategory: row.productLineCategory ? row.productLineCategory : 0,
                         ProductLineCategoryDescription: `${row.productLineCategoryDescription}`,
                         ItemNumber: row.itemNumber ? row.itemNumber : 0,
                         ItemDescription: `${row.itemDescription}`,
                         ManufacturerNumber: `${row.manufacturerNumber}`,
        
                         PriceType:  `${row.priceType}`,
                         PriceAmount: `${row.priceAmount}`,
                         PriceUnitOfMeasure: `${row.priceUom}`,
                         FloatPercent: `${row.floatPercent}`,

                         DeliveryEffectiveDate: `${row.deliveryEffectiveDate}`,
                         DeliveryExpirationDate: `${row.deliveryExpirationDate}`,

                         Action: `${row.action}`,
                         ExtendDate: `${row.extendDate}`,
                         NewTerms: !hasNewTerms 
                            ? null 
                            : { NewComments: `${row.newComments}`,
                                NewContractName: `${row.newContractName}`,
                                NewContractNumber: `${row.newContractNumber}`,
                                NewStartDate: `${row.newBeginDate}`,
                                NewEndDate: `${row.newEndDate}`,
                                NewPriceAmount: `${row.newPriceAmount}`,
                                NewPriceUnitOfMeasure: `${row.newPriceUom}` } satisfies NewExpirationTerms,

                         EmployeeNumber: row.employee ? row.employee : 0,
                         JobType: row.jobType ? row.jobType : 0,
                         SupplierIndicator: `${row.supplierResponsible}` } as SpecialPricingExpirationApiValidationRequestData;
            }
        );
        const requestModel: SpecialPricingExpirationExcelApiValidationRequest = 
        {
            TemplateFileName: fileName,
            CosmosGUID: id,
            SubmitterEmail: getUserEmail(),
            ChangeNotes: changeNotes,
            SpecialPricingExpirationTemplate: requestTemplate
        };

        return requestModel;
    };

    //Exports
    const testingExports = { doDateValidation,
                             convertToValidationModel,
                             convertToRequestModel };

    return {
        doClientSideValidation, 
        postToValidationApi,
        standardizeExcelPropertyNameFormat,
        testingExports
    };

}
