import "./PricingPortalPage.scss"
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { ExcelTemplateTypes } from "enums";
import { templateConfig } from 'utils';
import TemplateOptions from 'components/TemplateOptions/TemplateOptions';
import OverviewSection from "components/OverviewSection/OverviewSection";
import ViewTemplateSection from "components/ViewTemplateSection/ViewTemplateSection";
import WizardBar from "../../components/Wizard/WizardBar";


// Context Imported
import { ExcelDataProvider } from 'components/Contexts/ExcelDataContext';

export default function PricingPortalPage() {  
    const [templateType, setTemplateType] = useState<ExcelTemplateTypes>(ExcelTemplateTypes.None),
          [isUploading, setIsUploading] = useState(false),
          [changeNotes, setChangeNotes] = useState<string>(''),
          [priceChangeType, setPriceChangeType] = useState<string>(''),
          [isValid, setIsValid] = useState(false),
          [isReviewing, setIsReviewing] = useState(false);

    const showWizardBar = templateType === ExcelTemplateTypes.Supplier;

    return (
        <>
            <Helmet>
                <title>Pricing Portal | Template Drop</title>
            </Helmet>
            <div className="pricingPortalPageWrapper">
                <ExcelDataProvider>
                    <TemplateOptions templateProps={templateConfig}
                                     templateType={templateType}
                                     onTemplateTypeChange={(x) => setTemplateType(x)}
                                     setIsUploading={setIsUploading}
                                     changeNotes={changeNotes}
                                     setChangeNotes={setChangeNotes}
                                     priceChangeType={priceChangeType}
                                     setPriceChangeType={setPriceChangeType}
                                     setIsValid={setIsValid}
                                     setIsReviewing={setIsReviewing}
                    />
                    <WizardBar show={showWizardBar}
                               isUploading={isUploading}
                               isValid={isValid}
                               isReviewing={isReviewing}
                               priceChangeType={priceChangeType}

                    />
                    <OverviewSection/>
                    <ViewTemplateSection templateProps={templateConfig}
                                         templateType={templateType}
                                         isUploading={isUploading}
                                         text={changeNotes}
                                         setText={setChangeNotes}
                    />
                </ExcelDataProvider>
            </div>
        </>
    );
}
