import './ProgressBar.scss';

type Props = {
    approvePrimaryColor?: string
}

export default function ProgressBar({ approvePrimaryColor }: Props) {
    return (
        <div className="progressBarContainer">
            <div
                className={`progressFill ${approvePrimaryColor ? 'fill' : ''}`}
                style={{
                    ...(approvePrimaryColor && {
                        backgroundColor: approvePrimaryColor,
                    }),
                }}
            ></div>
        </div>
    );
}