import { FloatItemPricingExcelTemplate_Default, 
         SupplierPricingExcelTemplate_Default,
         FloatProductLinePricingExcelTemplate_Default,
         SpecialFixedPricingExcelTemplate_Default,
         SpecialPricingExpirationExcelTemplate_Default,
         TemplateTypeCheckResult } from 'types';
import { ExcelTemplateTypes } from 'enums';
import { ClientValidationError, ClientValidationErrorType } from 'errors';
import { useExcelValidationHelpers } from './useExcelValidationHelpers';

interface ExcelTypeComparisonData {
    type: string,
    keys: string[]
}

interface MismatchData {
    type: string,
    mismatches: number
}

interface TemplateTypeCompareData {
    Supplier: ExcelTypeComparisonData, 
    FloatItem: ExcelTypeComparisonData,
    FloatProductLine: ExcelTypeComparisonData,
    Fixed: ExcelTypeComparisonData,
    //Expiration: ExcelTypeComparisonData
}

export function useExcelTemplateTypeCheckService() {

    const { grammarHelper, 
            standardizePropertyNames } = useExcelValidationHelpers();
    const matchThreshold = 3;

    //Throws a ClientValidationError if the uploaded excel does not appear to 
    //match the selected template type, otherwise does nothing.
    const checkTemplateMatch = (rawExcel: any[], expected: ExcelTemplateTypes) => {
        const result: TemplateTypeCheckResult = getMatchResult(rawExcel, expected);
        if (result.match) return;
        else {
            const mismatchMessages = getTemplateHelperMessages(result.actual, expected);
            throw new ClientValidationError(ClientValidationErrorType.TypeMismatch,
                                            "One or more errors occured during validation.", 
                                            [...mismatchMessages]);
        }
    };

    //Runs the type check and constructs result object
    const getMatchResult = (rawExcel: any[], expected: ExcelTemplateTypes) => {
        let result: TemplateTypeCheckResult;
        if (!rawExcel || rawExcel.length === 0) {
            //We can't do a type check at all if there's no excel data, so just return a valid result
            //and let the client validation service flag it as empty when it checks headers
            result = { match: true, actual: expected } as TemplateTypeCheckResult;
        } else {
            const typesMatchingUpload = checkUploadedType(rawExcel);
            //If typeOfUpload can't be determined, don't try to suggest a correction, 
            if (typesMatchingUpload.indexOf(expected) !== -1) {
                result =  { match: true,
                            actual: expected } as TemplateTypeCheckResult;
            } else if (typesMatchingUpload && typesMatchingUpload.length) {
                result =  { match: false,
                            actual: typesMatchingUpload[0] } as TemplateTypeCheckResult;
            } else {
                result =  { match: false,
                            actual: ExcelTemplateTypes.None } as TemplateTypeCheckResult;
            }
        }
        return result;
    };

    //Returns the closest matching template type(s) of the raw excel data
    const checkUploadedType = (rawExcel: any[]) => {
        const noMatchResult = [] as ExcelTemplateTypes[],
              formatted = standardizePropertyNames<any>(rawExcel),
              excelKeys = Object.keys(formatted[0]);

        if (!excelKeys || excelKeys.length <= matchThreshold) return noMatchResult;

        const comparisons = {
            Supplier: {
                type: ExcelTemplateTypes.Supplier,
                keys: Object.keys(SupplierPricingExcelTemplate_Default)
            } as ExcelTypeComparisonData,
            FloatItem: {
                type: ExcelTemplateTypes.FloatItem,
                keys: Object.keys(FloatItemPricingExcelTemplate_Default)
            } as ExcelTypeComparisonData,
            FloatProductLine: {
                type: ExcelTemplateTypes.FloatProductLine,
                keys: Object.keys(FloatProductLinePricingExcelTemplate_Default)
            } as ExcelTypeComparisonData,
            Fixed: {
                type: ExcelTemplateTypes.Fixed,
                keys: Object.keys(SpecialFixedPricingExcelTemplate_Default)
            } as ExcelTypeComparisonData,
            Expiration: {
                type: ExcelTemplateTypes.Expiration,
                keys: Object.keys(SpecialPricingExpirationExcelTemplate_Default)
            } as ExcelTypeComparisonData
        } as TemplateTypeCompareData;

        //Check raw excel keys against keys of all template types and get nearest match(es)
        return compareTypes(comparisons, excelKeys);
    };

    //Do comparisons for similar types, build an array of results, return the one that's the closest match
    const compareTypes = (comparisons: TemplateTypeCompareData, excelDataKeys: string[]) => {
        const templateTypes = Object.getOwnPropertyNames(comparisons),
              results: MismatchData[] = templateTypes.flatMap((comparisonProp: string) => {
                  const key = comparisonProp as keyof typeof comparisons,
                        compareData = comparisons[key],
                        value = { mismatches: getMismatchedKeys(compareData, excelDataKeys).length,
                                  type: compareData.type } as MismatchData;
                  //This is to prevent (probably wrong) suggestions if the upload is not even close to a valid template type
                  return value.mismatches <= matchThreshold ? [value] : [];
              });
        //Sort ascending, return types of nearest match(es)
        const nearMatchTypes =  results.sort((a, b) => a.mismatches - b.mismatches)
                                       .filter(x => x.mismatches === results[0].mismatches);
        if (results && results.length && nearMatchTypes[0].mismatches <= matchThreshold) {
            return nearMatchTypes.map(x => x.type)
        }
        else return [ExcelTemplateTypes.None];
    };

    const getMismatchedKeys = (comparison: ExcelTypeComparisonData, excelDataKeys: string[]) => {
        const mismatches: string[] = [];
        for (const key of excelDataKeys) {
            if (!(comparison.keys).includes(key)) {
                mismatches.push(key);
            }
        }
        return mismatches;
    };

    const getTemplateHelperMessages = (actual: ExcelTemplateTypes, selected: ExcelTemplateTypes) => {
        const nonMatch = `Uploaded file does not appear to match selected template type of ${selected}.`;
        const wrongFileSuggestion = "This file does not appear to be a valid pricing template.",
              typeSuggestion = `If this file was ${grammarHelper(actual)}, please select that option ` +
                               `from the dropdown before resubmitting.`;
        return actual === ExcelTemplateTypes.None ? [wrongFileSuggestion] 
                                                  : [nonMatch, typeSuggestion]; 
    }

    const testingExports = {
        checkUploadedType,
        getMatchResult,
        getMismatchedKeys,
        compareTypes,
        getTemplateHelperMessages
    };

    return {
        checkTemplateMatch,
        testingExports
    };
}
