import TemplateValidationErrors from './TemplateValidationErrors';
import TemplateValidationSuccess from './TemplateValidationSuccess';
import TemplatePriceChangeSelection from './TemplatePriceChangeSelection';
import TemplateHeaderSection from './TemplateHeaderSection';
import { TemplateSelector, TemplateButton } from 'components';
import React, { useState, useRef, useEffect } from 'react';
import {
    useExcelTemplateSubmitService,
    useExcelHeaderPreprocessingService,
    useCosmosExcelConverterService,
    useTemplateHistoryService
} from 'services';
import { 
    ColorTypes, 
    ExcelTemplateTypes, 
    PriceChangeTypes, 
    TemplateLevelErrorKeys 
} from "enums";
import {
    ColorSettings,
    ExcelTemplateProps,
    ExcelViewerErrorData,
    PricingExcelApiResult,
    SupplierPricingTemplateMissingItemsApiRequest,
    TemplateSubmissionFlags,
    TemplateSubmissionFlags_Default,
    ValidationApiErrorList,
    ValidationApiTemplateLevelError
} from 'types';
import { ClientValidationError } from "errors";
import { ToggleElementProps, stripCaseAndSpaces, toggleElement } from 'utils';
import * as XLSX from 'xlsx';

//FontAwesome icons
import { faArrowRightToArc, faArrowDownToLine, faArrowUpFromLine } from "@fortawesome/pro-light-svg-icons";

// Context Imported
import { useExcelData } from 'components/Contexts/ExcelDataContext';
import { ExcelKeyValuePairs } from 'components/Contexts/ExcelKeyValuePairs';

import LoadingAnimation from './LoadingAnimation';
import TemplateApprovePanel from './TemplateApprovePanel';
import './TemplateOptions.scss';

//State for this is handled by the page (b/c it's required for the view template section)
interface Props {
    templateProps: ExcelTemplateProps
    templateType: ExcelTemplateTypes
    onTemplateTypeChange: (x: ExcelTemplateTypes) => void
    setIsUploading: (uploading: boolean) => void
    changeNotes: string
    setChangeNotes: (value: string) => void
    priceChangeType: string
    setPriceChangeType: (value: string) => void
    setIsValid: (valid: boolean) => void
    setIsReviewing: (reviewing: boolean) => void
}

export default function TemplateOptions({ templateProps, templateType, onTemplateTypeChange, setIsUploading, changeNotes, setChangeNotes, priceChangeType, setPriceChangeType, setIsValid, setIsReviewing }: Props) {

    // State
    // React.useState exposes state for unit tests
    const [selectedFile, setSelectedFile] = React.useState<File | ArrayBuffer | null>(null),
          [errorPanelTitle, setErrorPanelTitle] = useState<string>("(errorPanelTitle was not set properly - if you are" +
                                                                   "seeing this message, this is a bug)"),
          [errorPanelContent, setErrorPanelContent] = useState<string[]>([]),
          [successPanelFileName, setSuccessPanelFileName] = useState<string>(""),
          [cosmosId, setCosmosId] = useState<string>(""),
          [selectedPriceType, setSelectedPriceType] = useState<string>(PriceChangeTypes.None),

    //Flags
          [approvePanelDetails, setApprovePanelDetails] = useState<ValidationApiTemplateLevelError[]>([]),
          [renderSuccessPanel, setRenderSuccessPanel] = useState<boolean>(false),
          [isProcessingUpload, setIsProcessingUpload] = useState<boolean>(false),
          [enableButtons, setEnableButtons] = useState<boolean>(false),
          [toggleErrorPanel, setToggleErrorPanel] = useState<boolean>(false),
          [renderErrorPanel, setRenderErrorPanel] = useState<boolean>(false),
          [renderApprovePanel, setRenderApprovePanel] = useState<boolean>(false),
          [renderToggleErrorButton, setRenderToggleErrorButton] = useState<boolean>(false),
          [isSubmitting, setIsSubmitting] = useState<boolean>(false),
          [renderPriceChangePanel, setRenderPriceChangePanel] = useState<boolean>(false),
          [approvalStatus, setApprovalStatus] = useState<boolean>(false);
           
    // Context
    const { excelContext, errorContext } = useExcelData(),
          { excelData, setExcelData } = excelContext,
          { setExcelErrors } = errorContext,
          { getMissingProductData } = useTemplateHistoryService(),
          { convertSupplierPricingTemplateMissingItems } = useCosmosExcelConverterService();

    // Timeout for success panel
    useEffect(() => {
        let timer: any;
        if (renderSuccessPanel) {
            timer = setTimeout(() => setRenderSuccessPanel(false), 7500);
        }
        return () => {
            clearTimeout(timer);
        }
    }, [renderSuccessPanel, successPanelFileName]);

    //Services
    const fileName = (selectedFile as File)?.name ?? "";
    const { preprocessExcelTemplateHeaders } = useExcelHeaderPreprocessingService();
    const { submitSupplierTemplate,
            submitFloatItemTemplate,
            submitFloatProductLineTemplate,
            submitSpecialFixedTemplate,
            submitSpecialPricingExpirationTemplate } = useExcelTemplateSubmitService(cosmosId, fileName, changeNotes, priceChangeType, (x) => setExcelErrors(x));

    // References to file input and status text elements
    const fileInputRef = useRef<HTMLInputElement | null>(null),
          submitFileTextElement = useRef<HTMLParagraphElement | null>(null),
          uploadFileTextElement = useRef<HTMLParagraphElement | null>(null);

    // Other constants
    const { supplierPricingTemplate: supplierPricingTemplateConstants, 
            floatItemPricingTemplate: floatItemPricingTemplateConstants,
            floatProductLinePricingTemplate: floatProductLinePricingTemplateConstants,
            fixedPricingTemplate: fixedPricingTemplateConstants,
            extensionPricingTemplate: specialPricingExpirationTemplateConstants } = templateProps;

    const validationErrorText = "Please correct the error(s) and submit your Excel spreadsheet again.",
          apiErrorText = "Submission to validation service failed.",
          messageColors = {
              error: { colorType: ColorTypes.Custom, key: "high-vis-orange" } as ColorSettings,
              serious: { colorType: ColorTypes.Custom, key: "serious-error-red" } as ColorSettings,
              success: { colorType: ColorTypes.Dot, key: "dark-green" } as ColorSettings,
              pending: { colorType: ColorTypes.Dot, key: "dark-blue" } as ColorSettings
          };

    //Functions

    const handleDownloadMissingItems = async (approvePanelDetails: ValidationApiTemplateLevelError[]) => {

        setIsSubmitting(true); // Activate Loading Animation
        setIsReviewing(true); // Show Wizard step 2 loader icon

        // Transform `approvePanelDetails` to match `SupplierPricingTemplateMissingItemsApiRequest` structure
        const supplierPricingTemplateMissingItems: SupplierPricingTemplateMissingItemsApiRequest = approvePanelDetails.map(detail => {
            // Extract ProductLine from the Message string
            const productLineMatch = detail.Message.match(/product line (\d+)/);
            const productLine = productLineMatch ? parseInt(productLineMatch[1], 10) : 0;

            // Extract ItemNumbers from AdditionalDetails array, checking for null/undefined
            const itemNumbers = detail.AdditionalDetails
                ? detail.AdditionalDetails.map(itemDetail => {
                    const itemMatch = itemDetail.match(/Item: (\d+)/);
                    return itemMatch ? parseInt(itemMatch[1], 10) : 0;
                }).filter(itemNumber => itemNumber !== 0)
                : [];

            return {
                ProductLine: productLine,
                ItemNumbers: itemNumbers
            };
        });

        const missingProductPriceFxData = await getMissingProductData(supplierPricingTemplateMissingItems).catch((ex: unknown) => {
            setIsSubmitting(false); // Deactivate Loading Animation
            setIsReviewing(false); // Hide Wizard step 2 loader icon
            const errorMessage = ex instanceof Error ? ex.message : String(ex);
            throw new Error(`useTemplateHistoryService.getMissingProductDataOnPartialSubmission error: ${errorMessage}`);
        });

        convertSupplierPricingTemplateMissingItems(missingProductPriceFxData);

        setIsSubmitting(false); // Deactivate Loading Animation
        setIsReviewing(false); // Hide Wizard step 2 loader icon
    };

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the input element
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setExcelErrors([]);
        setExcelData(); // resets excel data
        setErrorPanelTitle(validationErrorText);
        submitFileTextElement.current!.style.visibility = 'hidden';
        const inProgressProps = getErrorTextProps(uploadFileTextElement.current, messageColors.pending, "* Uploading...");
        toggleElement(inProgressProps);

        const excelFileTypes = [".xlsx", ".xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.openxmlformats-officedocument.spreadsheet", "application/vnd.ms-excel", ".csv", "text/csv"];

        if (file) {
            // Incorrect file type uploaded
            if (!excelFileTypes.includes(file?.type)) {
                console.log(file.type);
                const props = getErrorTextProps(uploadFileTextElement.current, messageColors.error, "* Incorrect File Type");
                toggleElement(props);
            }
            // Excel file uploaded
            else {
                console.log("setting file");
                setSelectedFile(file);

                setRenderToggleErrorButton(false);
                setIsUploading(true);
                // Read and process the file
                await readExcelFile(file);
            }
        }

        resetFileInput(); // Reset the input to allow re-upload of the same file
    };


    const readExcelFile = async (file: File) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadstart = () => {
            setIsProcessingUpload(true);
            const inProgressProps = getErrorTextProps(uploadFileTextElement.current, messageColors.pending, "* Uploading...");
            toggleElement(inProgressProps);
        };
        reader.onloadend = () => {
            setIsProcessingUpload(false);
            setIsUploading(false);
            const doneProps = getErrorTextProps(uploadFileTextElement.current, messageColors.success, "* Ready To Submit");
            toggleElement(doneProps);
        }
        reader.onload = (e: ProgressEvent<FileReader>) => {

            const arrayBuffer = e.target?.result as ArrayBuffer;
            const workbook = XLSX.read(arrayBuffer, { type: 'buffer', cellDates: true });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName],
                  preprocessedWorksheet = preprocessExcelTemplateHeaders(worksheet);
            const rawData = XLSX.utils.sheet_to_json(
                preprocessedWorksheet, {
                header: 2,
                defval: "",
                blankrows: true,
            }) as ExcelKeyValuePairs[];

            // Filter out empty rows and parse dates
            const filteredData = rawData
                .filter(row => Object.values(row).some(value => value !== ""))
                .map(row => {
                    const kvps = Object.entries(row)
                                       .map(kvp => {
                                            const key = kvp[0],
                                                  expr = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})\s*T\d{2}:\d{2}:\d{2}\.?\d*Z?$/i;
                                            let value = (`${kvp[1] ?? ""}`).trim();
                                            if (value && expr.test(value)) {
                                                const extracted = expr.exec(value)?.groups,
                                                      month = extracted && extracted["month"],
                                                      day = extracted && extracted["day"],
                                                      year = extracted && extracted["year"];

                                                value = `${month}/${day}/${year}`;
                                            }
                                            return [key, value];
                                        });
                    return Object.fromEntries(kvps);
                });
            setExcelData(filteredData);
        };
    }


    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    const handleDownloadClick = () => {
        switch (templateType) {
            case ExcelTemplateTypes.Supplier:
                window.location.href = `${process.env.PUBLIC_URL}/pricingTemplates/Supplier_Excel_Template.xlsx`;
                break;
            case ExcelTemplateTypes.FloatItem:
                window.location.href = `${process.env.PUBLIC_URL}/pricingTemplates/Special_Float_Item_Excel_Template.xlsx`;
                break;
            case ExcelTemplateTypes.FloatProductLine:
                window.location.href = `${process.env.PUBLIC_URL}/pricingTemplates/Special_Float_Product_Line_Excel_Template.xlsx`;
                break;
            case ExcelTemplateTypes.Fixed:
                window.location.href = `${process.env.PUBLIC_URL}/pricingTemplates/Special_Fixed_Price_Excel_Template.xlsx`;
                break;
            case ExcelTemplateTypes.Expiration:
                window.location.href = `${process.env.PUBLIC_URL}/pricingTemplates/Special_Pricing_Expiration_Template.xlsx`;
                break;
            default:
                break;
        }
    };


    const handleSubmitClick = (flags?: TemplateSubmissionFlags, priceChangeType?: string) => {
        const noFileErrProps = getErrorTextProps(submitFileTextElement.current, messageColors.error, "* No File Uploaded");
        if (isProcessingUpload) return;
        else if (selectedFile && excelData != null) {
            const rawExcelJson = JSON.stringify(excelData);
            setExcelErrors([]);
            console.log("submitting with: ", priceChangeType);
            doExcelSubmission(rawExcelJson, flags, priceChangeType).then(response => handleApiResponse(response, priceChangeType));
        } else {
            console.log('No file selected.');
            toggleElement(noFileErrProps);
        }
    };

    //Send to the validation API endpoint for the selected template type
    const doExcelSubmission = async (data: string, flags?: TemplateSubmissionFlags, priceChangeType?: string) => {
        try {
            const waitingProps = getErrorTextProps(submitFileTextElement.current, messageColors.pending, "* Submitting..."),
                  { allowIncompleteProductLines } = flags ?? TemplateSubmissionFlags_Default;
            toggleElement(waitingProps);

            setIsSubmitting(true); // Activate Loading Animation
            if (priceChangeType == null) {
                setIsReviewing(true); // Show Wizard step 2 loader icon
            }

            let numHeaderRows: number,
                maxBrackets: number;

            switch (templateType) {
                case ExcelTemplateTypes.Supplier:
                    numHeaderRows = supplierPricingTemplateConstants.numHeaderRows;
                    maxBrackets = supplierPricingTemplateConstants.maxBrackets;
                    return await submitSupplierTemplate(data, numHeaderRows, maxBrackets, cosmosId, changeNotes, allowIncompleteProductLines, priceChangeType); 
                case ExcelTemplateTypes.FloatItem:
                    numHeaderRows = floatItemPricingTemplateConstants.numHeaderRows;
                    return await submitFloatItemTemplate(data, numHeaderRows, cosmosId, changeNotes);
                case ExcelTemplateTypes.FloatProductLine:
                    numHeaderRows = floatProductLinePricingTemplateConstants.numHeaderRows;
                    return await submitFloatProductLineTemplate(data, numHeaderRows, cosmosId, changeNotes);
                case ExcelTemplateTypes.Fixed:
                    numHeaderRows = fixedPricingTemplateConstants.numHeaderRows;
                    return await submitSpecialFixedTemplate(data, numHeaderRows, cosmosId, changeNotes);
                case ExcelTemplateTypes.Expiration:
                    numHeaderRows = specialPricingExpirationTemplateConstants.numHeaderRows;
                    return await submitSpecialPricingExpirationTemplate(data, numHeaderRows, cosmosId, changeNotes);
                default:
                    break;
            }
        } catch (ex: any) {
            if (ex instanceof ClientValidationError) {

                setIsSubmitting(false); // Deactivate Loading Animation
                setIsReviewing(false); // Hide Wizard step 2 loader icon
                 
                const errors = [...ex.problems],
                    errorTextProps = getErrorTextProps(submitFileTextElement.current, messageColors.error, "* Data Validation Error");
                errors.unshift(ex.message);
                displayErrorPanel(errors);
                toggleElement(errorTextProps);
            } else {

                setIsSubmitting(false); // Deactivate Loading Animation
                setIsReviewing(false); // Hide Wizard step 2 loader icon

                console.error(ex.message);
                throw ex;
            }
        }
    };

    const handleApiResponse = (apiResponse: PricingExcelApiResult | undefined, priceChangeType?: string) => {
        let errorTextProps: ToggleElementProps;
        const httpErrProps = getErrorTextProps(submitFileTextElement.current, messageColors.serious, "* Error While Submitting"),
            apiValidProps = getErrorTextProps(submitFileTextElement.current, messageColors.success, "* Submission Successful"),
            apiInvalidProps = getErrorTextProps(submitFileTextElement.current, messageColors.error, "* Data Validation Error"),
            uploadTextClear = getErrorTextProps(uploadFileTextElement.current, messageColors.pending, "");

        if (apiResponse?.postError) {
            errorTextProps = httpErrProps;
            const errors: string[] = [],
                  displayError = apiResponse.statusCode ? `${apiResponse.statusCode}: ${apiResponse.postError}`
                                                        : apiResponse.postError;
            errors.unshift(displayError);
            displayErrorPanel(errors, apiErrorText);
            toggleElement(errorTextProps);
            toggleElement(uploadTextClear);

            console.log("Error While Submitting...")
            setIsSubmitting(false); // Deactivate Loading Animation
            setIsReviewing(false); // Hide Wizard step 2 loader icon
        }
        else if (apiResponse?.response) {
            const isValid: boolean = apiResponse.response.data.Success;
            setIsSubmitting(false); // Deactivate Loading Animation
            setIsReviewing(false); // Hide Wizard step 2 loader icon
            setIsValid(isValid); // Store validation status for Review step in Wizard

            if (isValid) {
                console.log("Price Change Type: ", priceChangeType)
                // Additional Checks for the Price List Template submission (Price Change & Supplier Letter)
                if (templateType === ExcelTemplateTypes.Supplier && priceChangeType == null) { // priceChangeType not selected yet...

                    setRenderPriceChangePanel(true) // render Price Change Panel for selection

                    const id = apiResponse.response.data?.CosmosId ?? ""; 
                    setCosmosId(id);

                    console.log('price change needs to be selected...')
                    console.log('this templates ID: ', id)

                } else {

                    setExcelData(); // resets excel data, reset this flag so it doesn't persist for next submission
                    setIsValid(false) // resets valid status for wizard review step
                    setSelectedPriceType(PriceChangeTypes.None); // resets selected price type from dropdown
                    setPriceChangeType(""); // resets price change type for wizard price change type step
                    console.log("Data Submitted Successfully");
                    setCosmosId("");
                    setChangeNotes("");

                    const fileName = (selectedFile as File)?.name ?? "";
                    errorTextProps = apiValidProps;
                    setSuccessPanelFileName(fileName);

                    toggleElement(errorTextProps);
                    toggleElement(uploadTextClear);
                    setRenderSuccessPanel(true);
                    setRenderToggleErrorButton(false); 
                }
                
            } else {
                const id = apiResponse.response.data?.CosmosId ?? "";
                setCosmosId(id);

                errorTextProps = apiInvalidProps;

                console.log("Data Validation Error... ", apiResponse.response.data.TemplateErrors)

                const errors: string[] = [],
                       templateLevelErrors = apiResponse.response.data.TemplateErrors as ValidationApiTemplateLevelError[];

                if (templateLevelErrors) {
                    const templateMessages = templateLevelErrors.flatMap(err => {
                        if (err.ErrorKey === TemplateLevelErrorKeys.ProductLineIncomplete) 
                            return [`Warning - ${err.Message}`];
                        else 
                            return [`Template - ${err.Message} ${err.AdditionalDetails ? err.AdditionalDetails.join(", ") : "" }`];
                    });
                    errors.push(...templateMessages);
                }

                let responseData: ValidationApiErrorList[] = [],
                    offset = 0;
                switch (templateType) {
                    case ExcelTemplateTypes.Supplier:
                        offset = supplierPricingTemplateConstants.numHeaderRows + 2;
                        responseData = apiResponse!.response.data.SupplierPricingTemplate;
                        break;
                    case ExcelTemplateTypes.FloatItem:
                        offset = floatItemPricingTemplateConstants.numHeaderRows + 2;
                        responseData = apiResponse!.response.data.ItemFloatPricingTemplate;
                        break;
                    case ExcelTemplateTypes.FloatProductLine:
                        offset = floatProductLinePricingTemplateConstants.numHeaderRows + 2;
                        responseData = apiResponse!.response.data.ProductLineFloatPricingTemplate;
                        break;
                    case ExcelTemplateTypes.Fixed:
                        offset = fixedPricingTemplateConstants.numHeaderRows + 2;
                        responseData = apiResponse!.response.data.SpecialFixedPricingTemplate;
                        break;
                    case ExcelTemplateTypes.Expiration:
                        offset = specialPricingExpirationTemplateConstants.numHeaderRows + 2;
                        responseData = apiResponse!.response.data.SpecialPricingExpirationTemplate;
                        break;
                    default:
                        break;
                }

                //To determine row number, this assumes data rows are coming back in the same order we sent them.  
                //If that is not the case, it needs to be revised.
                const viewerData: ExcelViewerErrorData[] = [];
                for (const [index, lineItem] of responseData.entries()) {
                    const thisRow = lineItem as ValidationApiErrorList,
                        rowNumber = index + offset;
                    if (thisRow.RowErrors) {
                        //Construct error messages for panel and error data for excel viewer component
                        const messages = thisRow.RowErrors.map((err) => {
                            for (const keyStr of err.Key.split(',')) {
                                const key = stripCaseAndSpaces(keyStr),
                                    viewerError = new ExcelViewerErrorData(rowNumber, key, [err.ErrorDescription]);
                                viewerData.push(viewerError);
                            }
                            return `Data row ${rowNumber} - Error in column ${err.Key}, ${err.ErrorDescription}`;
                        });
                        errors.push(...messages);
                    }
                }

                const errText = (errors.length > 1) ? "errors are"
                                                    : "error is";

                //Check for errors that can be overridden by user approval
                const nonDismissable = templateLevelErrors.filter(x => x.ErrorKey !== TemplateLevelErrorKeys.ProductLineIncomplete);
                if (errors.length === templateLevelErrors.length && !nonDismissable.length) {
                    displayApprovePanel(templateLevelErrors);
                } else {
                    errors.unshift(`The server cannot accept this template until the following ${errText} corrected.`);
                    displayErrorPanel(errors);
                }
                toggleElement(errorTextProps);
                setExcelErrors([...viewerData]);
                toggleElement(uploadTextClear);
            }
        }
    };

    const displayErrorPanel = (errors: string[], panelLowerText: string = validationErrorText) => {
        setErrorPanelTitle(panelLowerText);
        setErrorPanelContent(errors);
        setRenderErrorPanel(true);
        setRenderToggleErrorButton(true);
    };

    const displayApprovePanel = (details: ValidationApiTemplateLevelError[]) => {
        setRenderApprovePanel(true);
        setApprovePanelDetails(details);
    }

    const handleCloseApprovePanel = (isApproved: boolean) => {

        setApprovalStatus(isApproved); // Store approval status
        setIsValid(isApproved); // Store validation status for Review step in Wizard
        setRenderApprovePanel(false);

        if (isApproved) {
            setTimeout(() => {                      // slight delay to give Approval Panel time to go away
                setRenderPriceChangePanel(true);
            }, 200);
            //handleSubmitClick({allowIncompleteProductLines: true});  // Not calling handleSubmitClick here... waiting for price change panel submission
        }
        setTimeout(() => setApprovePanelDetails([]), 500); //Prevents a nasty visual bug when panel is closed
    };

    const handleContinuePriceChangePanel = (priceChangeSelectionCompleted: boolean) => {

        setRenderPriceChangePanel(false)

        if (priceChangeSelectionCompleted) {

            handleSubmitClick(
                approvalStatus ? { allowIncompleteProductLines: true } : undefined,
                selectedPriceType
            );
        }
    };

    const handlePriceTypeChange = (value: string) => {
        setSelectedPriceType(value);
        setPriceChangeType(value);
        console.log("Selected Price Type:", value);
    };

    const handleDismissValidationErrors = () => {
        setToggleErrorPanel(prev => !prev);
        setRenderErrorPanel(false);
    };

    const toggleErrors = (errors: string[]) => {
        setToggleErrorPanel(prev => !prev);

        if (!toggleErrorPanel) {
            console.log(toggleErrorPanel + ": Hide Errors!");
            setRenderErrorPanel(false);
        }
        else if (toggleErrorPanel) {
            console.log(toggleErrorPanel + ": Show Errors!");
            setRenderErrorPanel(true);
        }
    }

    const getErrorTextProps = (element: HTMLParagraphElement | null, settings: ColorSettings, message: string, visible: boolean = true) => {
        return {
            element: element,
            shouldBeVisible: visible,
            colorType: settings.colorType,
            colorName: settings.key,
            text: message
        } as ToggleElementProps;
    };

    const handleTemplateTypeChange = (type: ExcelTemplateTypes) => {
        onTemplateTypeChange(type);
        setEnableButtons(true);
        setRenderToggleErrorButton(false);
    }

    return (
        <>
            {isSubmitting && <LoadingAnimation render={isSubmitting} />}
            <div className="containerWrapper">
                <div className="templateOptionsSection">
                    <div className="templatePrompt">
                        <TemplateHeaderSection templateType={templateType} hasUpload={!(!(excelData && excelData.length > 0))} />
                    </div>
                    <TemplateValidationSuccess render={renderSuccessPanel}
                        fileName={successPanelFileName}
                        dismissCallback={() => setRenderSuccessPanel(false)} />
                    {cosmosId && (
                        <TemplateApprovePanel
                            render={renderApprovePanel}
                            details={approvePanelDetails}
                            dismissCallback={handleCloseApprovePanel}
                            onDownloadMissingItems={handleDownloadMissingItems}
                        />
                    )}
                    <div id="templateValidationErrors">
                        <TemplateValidationErrors render={renderErrorPanel}
                            toggleRender={renderToggleErrorButton}
                            content={errorPanelContent}
                            message={errorPanelTitle}
                            dismissCallback={handleDismissValidationErrors}
                            toggleErrors={() => toggleErrors(errorPanelContent)} />
                    </div>
                    <div id="templatePriceChangeSelection">
                        <TemplatePriceChangeSelection
                            render={renderPriceChangePanel}
                            dismissCallback={handleContinuePriceChangePanel}
                            onPriceTypeChange={handlePriceTypeChange}
                        />
                    </div>
                    <div className="templateSelectorContainer">
                        <TemplateSelector className="templateSelector"
                            selected={templateType}
                            onTemplateChange={handleTemplateTypeChange} />
                    </div>
                    <div id="templateUploadButtonRow">
                        <input type='file' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
                        <div className="uploadBtn">
                            <TemplateButton text='UPLOAD'
                                disabled={!enableButtons}
                                btnIcon={faArrowUpFromLine}
                                onClick={handleUploadClick} />
                            <p data-testid="buttonErrorText" ref={uploadFileTextElement}
                                style={{ visibility: 'hidden' }}
                                className='templateBtnText'
                                id='uploadFileText'></p>
                        </div>
                        <div className="submitBtn">
                            <TemplateButton text='SUBMIT'
                                disabled={!enableButtons}
                                btnIcon={faArrowRightToArc}
                                onClick={handleSubmitClick} />
                            <p data-testid="buttonErrorText" ref={submitFileTextElement}
                                style={{ visibility: 'hidden' }}
                                className='templateBtnText'
                                id='fileErrorText'>* No File Uploaded</p>
                        </div>
                        <div className="downloadBtn">
                            <TemplateButton text='DOWNLOAD'
                                disabled={!enableButtons}
                                btnIcon={faArrowDownToLine}
                                onClick={handleDownloadClick} />
                            <p data-testid="buttonInfoText" className={`templateBtnText ${!enableButtons ? 'disabled' : ""}`}>Download New Template</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
