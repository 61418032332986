import { useState, useEffect } from "react";
import "./TemplatePriceChangeSelection.scss";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { PriceChangeTypes } from "../../enums";
import TemplateButton from "../Buttons/TemplateButton";
import { faArrowRightToArc } from "@fortawesome/pro-light-svg-icons";

interface Props {
    render: boolean,
    dismissCallback: (x: boolean) => void,
    onPriceTypeChange: (value: string) => void;
}

export default function TemplatePriceChangeSelection({ render, dismissCallback, onPriceTypeChange }: Props) {

    const [selectedPriceType, setSelectedPriceType] = useState<string>(PriceChangeTypes.None);

    const priceTypes = Object.values(PriceChangeTypes).map((x, i) => {
        let isDisabled = false;
        if (x === PriceChangeTypes.None) isDisabled = true; 
        return { id: i, name: x, disabled: isDisabled };
    });

    const handlePriceTypeChange = (value: string) => {
        setSelectedPriceType(value); 
        onPriceTypeChange(value);  
    };

    const isSubmitDisabled = !selectedPriceType || selectedPriceType === PriceChangeTypes.None;

    useEffect(() => {
        if (render) {
            setSelectedPriceType(PriceChangeTypes.None);
        }
    }, [render]);
    
    return ( render && (
        <>
            <div className="overlay">
                <div className="priceChangePanel">
                    <p>Select a price change for this template</p>
                    <div className="priceChangeSelectorContainer">
                        <DropDownList
                            className="templateSelectorDropdown"
                            defaultValue={{ name: PriceChangeTypes.None }}
                            data={priceTypes}
                            dataItemKey='id'
                            title='Select Price Change'
                            textField='name'
                            fillMode={"solid"}
                            skipDisabledItems={true}
                            popupSettings={{
                                className: "fixedDropdown", // Add a specific class to prevent scrolling bug
                            }}
                            onChange={(e) => handlePriceTypeChange(e.target.value?.name)} // Update selected value
                        />
                    </div>
                    <TemplateButton className="continueButton"
                        text="CONTINUE"
                        btnIcon={faArrowRightToArc}
                        disabled={isSubmitDisabled} // Disable if no valid price change type selection
                        onClick={() => dismissCallback(true)}></TemplateButton>
                </div>
            </div>
        </>
    ));
}

