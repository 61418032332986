import { TemplateHistoryApiResultObject } from "./TemplateHistoryApiResult";
import { ContractNameNumber, 
         CustomerNameId, 
         ItemNameNumber, 
         ProductLineCategoryNameNumber, 
         ProductLineNameNumber } from "./TemplateMetadataModels";

export type SpecialPricingExpirationTemplateHistoryApiResultObject = 
    TemplateHistoryApiResultObject & 
    SpecialPricingExpirationTemplateHistoryMetadata;

export type SpecialPricingExpirationTemplateHistoryMetadata = {
    Customers: CustomerNameId[] | null,
    Items: ItemNameNumber[] | null,
    Contracts: ContractNameNumber[] | null,
    ProductLines: ProductLineNameNumber[] | null,
    ProductLineCategories: ProductLineCategoryNameNumber[] | null,
    HasNewTerms: boolean | null
}

export const SpecialPricingExpirationTemplateHeaderRows_AoA = [
    [
        "CONTROL NUMBER",
        "CUSTOMER NUMBER",
        "CUSTOMER SHIPTO",
        "CUSTOMER NAME",
        "CUSTOMER ADDRESS",
        "PRODUCT LINE NUMBER",
        "PRODUCT LINE DESCRIPTION",
        "PRODUCT CATEGORY",
        "CATEGORY DESCRIPTION",
        "EMP RESPONSIBLE PRICING",
        "JOB TYPE",
        "SUPPLIER RESPONSIBLE Y/N",
        "ITEM NUMBER",
        "ITEM DESCRIPTION",
        "MANUFACTURERS NUMBER",
        "CONTRACT NUMBER",
        "CONTRACT NAME",
        "PRICE TYPE",
        "PRICING AMOUNT",
        "FLOAT PERCENT",
        "PRICING UOM",
        "DELIVERY EFFECTIVE DATE",
        "DELIVERY EXPIRATION DATE",
        "EXTEND/DELETE/CANCEL/NEW",
        "EXTEND DATE",
        "COMMENTS",
        "NEW PRICING AMOUNT",
        "NEW PRICE UOM",
        "NEW CONTRACT NUMBER",
        "NEW CONTRACT NAME",
        "NEW START DATE",
        "NEW END DATE",
        "COMMENTS"
    ]
];
