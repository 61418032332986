import { useOktaAuth } from "@okta/okta-react/";
import { AuthHeader } from "types";

export const useSecurityService = () => {
    const { authState, oktaAuth } = useOktaAuth();

    const getAuthHeader = async (): Promise<AuthHeader> => {
        const accessToken = oktaAuth.getAccessToken(),
              authHeader: AuthHeader = {
                'Content-Type': "application/json",
                Authorization: `Bearer ${accessToken}`,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY ?? ""
              };

        if (process.env.REACT_APP_DEBUG_CONSOLE_LOGGING?.toString() === "true")
            console.log(authHeader); // Needs to be removed eventually... 

        return authHeader;
    }

    const getUserEmail = (): string => authState?.idToken?.claims.email ?? "";

    return {
        getAuthHeader,
        getUserEmail
    };
}
