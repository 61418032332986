import React, { useEffect, useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { ExcelTemplateTypes } from 'enums';
import './TemplateSelector.scss';

interface Props {
    onTemplateChange: (value: ExcelTemplateTypes) => void,
    className?: string,
    selected: ExcelTemplateTypes
}

export default function TemplateSelector({onTemplateChange, selected, className = ""}: Props) {
    const [pulseHighlightState, setPulseHighlightState] = useState<boolean>();
    const arrowRef = React.createRef<HTMLDivElement>();

    const templateTypes = Object.values(ExcelTemplateTypes)
                                .map((x, i) => { 
                                    let isDisabled = false;
                                    if (x === ExcelTemplateTypes.None) isDisabled = true;
                                    return { id: i, name: x, disabled: isDisabled }; 
                                });
    
    //Prevents the indicator arrow from rendering a transition immediately on page load
    useEffect(() => {
        let fadeInTimer = setTimeout(() => {
            if (arrowRef.current != null) {
                arrowRef.current.classList.remove("delayTransition");
            }
        }, 750);
        return () => {
            clearTimeout(fadeInTimer);
        }
    }, [arrowRef]);

    //Looping timer for the highlight dropdown effect
    useEffect(() => {
        const timer = setInterval(() => 
        {
            if (selected === ExcelTemplateTypes.None) {
                setPulseHighlightState(!pulseHighlightState);
            } else {
                //Stop resetting the timer once a selection has been made
                setPulseHighlightState(false);
            }
        }, pulseHighlightState ? 3000 : 1200);
        return () => {
            clearInterval(timer);
        }
    }, [pulseHighlightState, selected]);

    const dropdownCallback = (event: DropDownListChangeEvent) => {
        onTemplateChange(event.target.value.name);
    }

    //JSX for dropdown items
    const dropdownItemRender = (li: any) => {
        const childElement = (
            <span className="templateSelectorDropdownElement">{li.props.children}</span>
        );
        return React.cloneElement(li, li.props, childElement);
    }

    return (
        <div id="templateSelectorContainer" className={className} >
            <DropDownList 
                className={`templateSelectorDropdown ${pulseHighlightState ? "k-focus pulseClass" : ""}`}
                defaultValue={{name: ExcelTemplateTypes.None}}
                data={templateTypes}
                dataItemKey='id'
                title='Select Template Dropdown'
                textField='name'
                fillMode={"solid"}
                itemRender={dropdownItemRender}
                onChange={dropdownCallback}
                skipDisabledItems={true}
            />
        </div>
    );
}

