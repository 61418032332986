import { TemplateHistoryApiResultObject } from "./TemplateHistoryApiResult";
import { ContractNameNumber, CustomerNameId, ItemNameNumber } from "./TemplateMetadataModels";

export type SpecialFixedTemplateHistoryApiResultObject = 
    TemplateHistoryApiResultObject & 
    SpecialFixedTemplateHistoryMetadata;

export type SpecialFixedTemplateHistoryMetadata = {
    Customers: CustomerNameId[] | null,
    Items: ItemNameNumber[] | null,
    Contracts: ContractNameNumber[] | null
}

export const SpecialFixedTemplateHeaderRows_AoA = [
    [
        "Customer Number",
        "Shipto",
        "Name",
        "Item Number",
        "Description",
        "Manufacturer",
        "Contract Number",
        "Contract Name",
        "Price",
        "UOM",
        "Begin Date",
        "End Date",
        "Employee",
        "Job Type",
        "Supplier Responsible",
        "Comments",
        "Promo Flag",
        "National Acct",
        "Float Flag",
        "Adj Y/N",
        "Auto Adj Y/N",
        "Adj 1 Type",
        "Adj 1 Amt",
        "Adj 1 %",
        "Adj 2 type",
        "Adj 2 Amt",
        "Adj 2 %",
        "Adj 3 Type",
        "Adj 3 Amt",
        "Adj 3 %"
    ]
];
