import qs from "qs";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ProductLineFloatTemplateSearchTypes, 
         SpecialFixedTemplateSearchTypes, 
         SpecialPricingExpirationTemplateSearchTypes, 
         SupplierTemplateSearchTypes, 
         TemplateStatus } from "enums";
import { useSecurityService } from "services/useSecurityService";
import { PricingTemplateHistoryApiResult, 
         ProductLineFloatTemplateHistoryApiQuery, 
         ProductLineFloatTemplateHistorySearch, 
         SpecialFixedTemplateHistoryApiQuery, 
         SpecialFixedTemplateHistorySearch, 
         SupplierPricingTemplateHistoryApiQuery, 
         SupplierPricingTemplateHistorySearch, 
         TemplateHistoryApiResponse,
         SupplierPricingTemplateMissingItemsApiResponse,
         SupplierPricingTemplateMissingItemsApiRequest, 
         SpecialPricingExpirationTemplateHistorySearch,
         SpecialPricingExpirationTemplateHistoryApiQuery } from "types";
import { prettyStringify } from "utils";

export function useTemplateHistoryService() {

    const apiUrls = {
        getSupplierPricingHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_SUPPLIER_PRICING_HISTORY_API ?? "",
        getItemFloatPricingHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_FLOAT_ITEM_PRICING_HISTORY_API ?? "",
        getProductLineFloatPricingHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_FLOAT_PRODUCT_LINE_PRICING_HISTORY_API ?? "",
        getSpecialFixedPricingHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_SPECIAL_FIXED_PRICING_HISTORY_API ?? "",
        getSpecialPricingExpirationHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_SPECIAL_PRICING_EXPIRATION_HISTORY_API ?? "",
        getMissingItemPriceFxProductData: process.env.REACT_APP_SALES_PRICINGIMPORT_MISSING_ITEM_PRODUCT_DATA_API ?? ""
    };

    const { getAuthHeader } = useSecurityService();

    const getSupplierPricingTemplateHistory = async (searchParams: SupplierPricingTemplateHistorySearch): Promise<PricingTemplateHistoryApiResult> => {
        const apiUrl = apiUrls.getSupplierPricingHistory,
              queryBuilder = createSupplierPricingHistoryQuery;
        return await callHistoryApi(searchParams, apiUrl, queryBuilder).catch(ex => {
            throw Error(ex.toString());
        });
    };

    const getProductLineFloatPricingTemplateHistory = async (searchParams: SpecialFixedTemplateHistorySearch): Promise<PricingTemplateHistoryApiResult> => {
        const apiUrl = apiUrls.getProductLineFloatPricingHistory,
              queryBuilder = createProductLineFloatPricingHistoryQuery;
        return await callHistoryApi(searchParams, apiUrl, queryBuilder).catch(ex => {
            throw Error(ex.toString());
        });
    }

    const getSpecialFixedPricingTemplateHistory = async (searchParams: SpecialFixedTemplateHistorySearch): Promise<PricingTemplateHistoryApiResult> => {
        const apiUrl = apiUrls.getSpecialFixedPricingHistory,
              queryBuilder = createSpecialFixedPricingHistoryQuery;
        return await callHistoryApi(searchParams, apiUrl, queryBuilder).catch(ex => {
            throw Error(ex.toString());
        });
    }

    const getSpecialPricingExpirationTemplateHistory = async (searchParams: SpecialPricingExpirationTemplateHistorySearch): Promise<PricingTemplateHistoryApiResult> => {
        const apiUrl = apiUrls.getSpecialPricingExpirationHistory,
              queryBuilder = createSpecialPricingExpirationHistoryQuery;
        return await callHistoryApi(searchParams, apiUrl, queryBuilder).catch(ex => {
            throw Error(ex.toString());
        });
    }

    const callHistoryApi = 
        async <TParams, TQuery>(searchParams: TParams, apiUrl: string, queryBuilder: (params: TParams) => TQuery): Promise<PricingTemplateHistoryApiResult> => {
            const queryParams = queryBuilder(searchParams),
                  config: AxiosRequestConfig = { headers: await getAuthHeader(), 
                                                 params: queryParams,
                                                 paramsSerializer: (x) => {
                                                     return qs.stringify(x, { arrayFormat: "repeat" })
                                                 }};
            try {
                const response = await axios.get(apiUrl, config),
                status = response.data?.StatusCode || response.status,
                resultObj = response.data as TemplateHistoryApiResponse;

                if (`${process.env.REACT_APP_DEBUG_CONSOLE_LOGGING}` === "true")
                    console.info(`HTTP GET response from "${apiUrl}":\n ${prettyStringify(response)}`);
                
                return { response: resultObj,
                         error: "",
                         statusCode: status } as PricingTemplateHistoryApiResult;
            }
            catch (ex: any) {
                const error = ex as AxiosError,
                      status = error.response?.status ?? 0;
                console.error(error.message);
                return { response: { MoreResults: false, Page: 1, Results: [] }, 
                         error: error.message ? error.message 
                                              : `Error fetching data from template history API` + 
                                                `${error.response?.statusText ? `: ${error.response.statusText}` 
                                                                            : ""}`,
                         statusCode: status } as PricingTemplateHistoryApiResult;
            }
    };

    const getMissingProductData = async (missingItems: SupplierPricingTemplateMissingItemsApiRequest) => {
        const apiUrl = apiUrls.getMissingItemPriceFxProductData,
            config = {
                headers: await getAuthHeader(),
            };

        try {
            const response = await axios.post(apiUrl, missingItems, config);
            const status = response.data?.StatusCode || response.status;
            const dataObj = response.data as unknown; 

            if (process.env.REACT_APP_DEBUG_CONSOLE_LOGGING === "true") {
                console.info(`HTTP POST response from "${apiUrl}":\n ${prettyStringify(response)}`);
            }

            // Return data as SupplierPricingTemplateMissingItemsApiResponse
            return {
                status: status,
                data: dataObj
            } as SupplierPricingTemplateMissingItemsApiResponse;

        } catch (ex) {
            const error = ex as AxiosError;
            const status = error.response?.status ?? 0;
            console.error(error.message);
            return {
                status: status
            } as SupplierPricingTemplateMissingItemsApiResponse;
        }
    };

    const createSupplierPricingHistoryQuery = (searchParams: SupplierPricingTemplateHistorySearch): SupplierPricingTemplateHistoryApiQuery => {
        const terms = searchParams.searchTerms.map(x => x.trim()),
              status = searchParams.templateStatus?.toString() ?? TemplateStatus.Any.toString(),
              page = searchParams.searchPage ?? 1;

        switch (searchParams.searchType) {
            case SupplierTemplateSearchTypes.TemplateId:
                return {
                    SearchBy: SupplierTemplateSearchTypes.TemplateId.toString(),
                    Page: page.toString(),
                    TemplateId: terms[0]
                };
            case SupplierTemplateSearchTypes.VendorName:
                return {
                    SearchBy: SupplierTemplateSearchTypes.VendorName.toString(),
                    Page: page.toString(),
                    Status: status,
                    VendorName: terms
                };
            case SupplierTemplateSearchTypes.VendorNumber:
                return {
                    SearchBy: SupplierTemplateSearchTypes.VendorNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    VendorNumber: terms
                };
            case SupplierTemplateSearchTypes.ProductLineName:
                return {
                    SearchBy: SupplierTemplateSearchTypes.ProductLineName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineName: terms
                };
            case SupplierTemplateSearchTypes.ProductLineNumber:
                return {
                    SearchBy: SupplierTemplateSearchTypes.ProductLineNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineNumber: terms
                };
            default:
                throw Error(`useTemplateHistoryService.createSupplierPricingHistoryQuery: Search ` +
                            `type "${searchParams.searchType}" query constructor is not implemented, ` + 
                            `this is a bug and should be reported.`);
        }
    }

    const createProductLineFloatPricingHistoryQuery = (searchParams: ProductLineFloatTemplateHistorySearch): ProductLineFloatTemplateHistoryApiQuery => {
        const terms = searchParams.searchTerms.map(x => x.trim()),
              status = searchParams.templateStatus?.toString() ?? TemplateStatus.Any.toString(),
              page = searchParams.searchPage ?? 1;

        switch (searchParams.searchType) {
            case ProductLineFloatTemplateSearchTypes.TemplateId:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.TemplateId.toString(),
                    Page: page.toString(),
                    TemplateId: terms[0]
                };
            case ProductLineFloatTemplateSearchTypes.CustomerId:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.CustomerId.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerId: terms
                };
            case ProductLineFloatTemplateSearchTypes.CustomerName:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.CustomerName.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerName: terms
                };
            case ProductLineFloatTemplateSearchTypes.ProductLineCategoryNumber:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.ProductLineCategoryNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineCategoryNumber: terms
                };
            case ProductLineFloatTemplateSearchTypes.ProductLineNumber:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.ProductLineNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineNumber: terms
                };
            case ProductLineFloatTemplateSearchTypes.ProductLineName:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.ProductLineName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineName: terms
                };
            case ProductLineFloatTemplateSearchTypes.ContractNumber:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.ContractNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractNumber: terms
                };
            case ProductLineFloatTemplateSearchTypes.ContractName:
                return {
                    SearchBy: ProductLineFloatTemplateSearchTypes.ContractName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractName: terms
                };
            default:
                throw Error(`useTemplateHistoryService.createProductLineFloatPricingHistoryQuery: Search ` +
                            `type "${searchParams.searchType}" query constructor is not implemented, ` + 
                            `this is a bug and should be reported.`);
        }
    };

    const createSpecialFixedPricingHistoryQuery = (searchParams: SpecialFixedTemplateHistorySearch): SpecialFixedTemplateHistoryApiQuery => {
        const terms = searchParams.searchTerms.map(x => x.trim()),
              status = searchParams.templateStatus?.toString() ?? TemplateStatus.Any.toString(),
              page = searchParams.searchPage ?? 1;

        switch (searchParams.searchType) {
            case SpecialFixedTemplateSearchTypes.TemplateId:
                return {
                    SearchBy: SupplierTemplateSearchTypes.TemplateId.toString(),
                    Page: page.toString(),
                    TemplateId: terms[0]
                };
            case SpecialFixedTemplateSearchTypes.CustomerId:
                return {
                    SearchBy: SpecialFixedTemplateSearchTypes.CustomerId.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerId: terms
                };
            case SpecialFixedTemplateSearchTypes.CustomerName:
                return {
                    SearchBy: SpecialFixedTemplateSearchTypes.CustomerName.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerName: terms
                };
            case SpecialFixedTemplateSearchTypes.ItemNumber:
                return {
                    SearchBy: SpecialFixedTemplateSearchTypes.ItemNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ItemNumber: terms
                };
            case SpecialFixedTemplateSearchTypes.ContractNumber:
                return {
                    SearchBy: SpecialFixedTemplateSearchTypes.ContractNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractNumber: terms
                };
            case SpecialFixedTemplateSearchTypes.ContractName:
                return {
                    SearchBy: SpecialFixedTemplateSearchTypes.ContractName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractName: terms
                };
            default:
                throw Error(`useTemplateHistoryService.createSpecialFixedPricingHistoryQuery: Search ` +
                            `type "${searchParams.searchType}" query constructor is not implemented, ` + 
                            `this is a bug and should be reported.`);
        }
    };
    
    const createSpecialPricingExpirationHistoryQuery = (searchParams: SpecialPricingExpirationTemplateHistorySearch): SpecialPricingExpirationTemplateHistoryApiQuery => {
        const terms = searchParams.searchTerms.map(x => x.trim()),
              status = searchParams.templateStatus?.toString() ?? TemplateStatus.Any.toString(),
              page = searchParams.searchPage ?? 1;

        switch (searchParams.searchType) {
            case SpecialPricingExpirationTemplateSearchTypes.TemplateId:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.TemplateId.toString(),
                    Page: page.toString(),
                    TemplateId: terms[0]
                };
            case SpecialPricingExpirationTemplateSearchTypes.CustomerId:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.CustomerId.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerId: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.CustomerName:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.CustomerName.toString(),
                    Page: page.toString(),
                    Status: status,
                    CustomerName: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ProductLineCategoryNumber:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ProductLineCategoryNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineCategoryNumber: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ProductLineNumber:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ProductLineNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineNumber: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ProductLineName:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ProductLineName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ProductLineName: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ItemName:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ItemName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ItemName: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ItemNumber:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ItemNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ItemNumber: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ContractNumber:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ContractNumber.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractNumber: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.ContractName:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.ContractName.toString(),
                    Page: page.toString(),
                    Status: status,
                    ContractName: terms
                };
            case SpecialPricingExpirationTemplateSearchTypes.Action:
                return {
                    SearchBy: SpecialPricingExpirationTemplateSearchTypes.Action.toString(),
                    Page: page.toString(),
                    Status: status,
                    Action: terms
                };
            default:
                throw Error(`useTemplateHistoryService.createSpecialPricingExpirationHistoryQuery: Search ` +
                            `type "${searchParams.searchType}" query constructor is not implemented, ` + 
                            `this is a bug and should be reported.`);
        }
    };

    const testingExports = {
        createSupplierPricingHistoryQuery,
        createProductLineFloatPricingHistoryQuery,
        createSpecialFixedPricingHistoryQuery,
        createSpecialPricingExpirationHistoryQuery,
        callHistoryApi
    };

    return {
        testingExports,
        getSupplierPricingTemplateHistory,
        getProductLineFloatPricingTemplateHistory,
        getSpecialFixedPricingTemplateHistory,
        getSpecialPricingExpirationTemplateHistory,
        getMissingProductData
    };
}