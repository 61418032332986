import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { TemplateStatus } from "enums";
import { HistoryDisplayTableData, 
         ProductLineFloatTemplateHistoryApiResultObject, 
         SpecialFixedTemplateHistoryApiResultObject, 
         SpecialPricingExpirationTemplateHistoryApiResultObject, 
         SupplierPricingTemplateHistoryApiResultObject, 
         TemplateHistoryApiResultObject, 
         TextExpandPanelProps } from "types";
import { getEnumKeys } from "utils";

export function useHistoryTableDataProcessingService() {

    const helpers = {
        cellListFormatter: (listContent: string) => {
            const formatted =  listContent.split(",")
                                          .map((substr) => helpers.fixAllCaps(substr))
                                          .join("\n");
            return formatted;
        },

        fixAllCaps: (str: string) => {
            if (!str) return "";
            const nonWordChar = /[^'\w]/i,
                  output = str.toLocaleLowerCase()
                              .trim()
                              .split("")
                              .map((char, index, array) => {
                                  return index === 0 || nonWordChar.test(array[index-1]) ? char.toLocaleUpperCase()
                                                                                         : char;
                              })
                              .join("");
            return output;
        },

        aggregators: {
            supplierPricingTemplateColumns: (data: TemplateHistoryApiResultObject[]) => data.map(x => {
                const details = (x as SupplierPricingTemplateHistoryApiResultObject),
                      nbsp = "\u00a0", //non-breaking space
                      vendorString = details ? (details.Vendors?.map((v) => `${v.VendorName}${nbsp}(${v.VendorNumber ?? "N/A"})`)
                                                                .join(", ") ?? "") 
                                             : "",
                      productLineString = details ? (details.ProductLines?.map((p) => `${p.ProductLineName}${nbsp}(${p.ProductLineNumber ?? "N/A"})`)
                                                                          .join(", ") ?? "") 
                                                  : "";
                return { ...x, 
                        IsComplete: !(x as any)["MissingItems"], 
                        Vendors: vendorString, 
                        ProductLines: productLineString };
            }) as HistoryDisplayTableData[],
    
            specialFixedPricingTemplateColumns: (data: TemplateHistoryApiResultObject[]) => data.map(x => {
                const details = (x as SpecialFixedTemplateHistoryApiResultObject),
                      nbsp = "\u00a0", //non-breaking space
                      customerString = details ? (details.Customers?.map((c) => `${c.CustomerName}${nbsp}(${c.CustomerId ?? "N/A"})`)
                                                                    .join(", ") ?? "") 
                                               : "",
                      contractString = details ? (details.Contracts?.map((i) => `${i.ContractName}${nbsp}(${i.ContractNumber ?? "N/A"})`)
                                                                    .join(", ") ?? "")
                                               : "",
                      itemString = details ? (details.Items?.map((i) => `${i.ItemName}${nbsp}(${i.ItemNumber ?? "N/A"})`)
                                                            .join(", ") ?? "")
                                           : "";
                
                return { ...x,
                        Customers: customerString,
                        Items: itemString,
                        Contracts: contractString };
            }),
    
            //WIP
            // itemFloatPricingTemplateColumns: (data: TemplateHistoryApiResultObject[]) => (data: TemplateHistoryApiResultObject[]) => data.map(x => {
            //     const details = (x as ItemFloatTemplateHistoryApiResultObject),
            //           nbsp = "\u00a0", //non-breaking space
            //           customerString = details ? (details.Customers?.map((c) => `${c.CustomerName}${nbsp}(${c.CustomerId ?? "N/A"})`)
            //                                                         .join(", ") ?? "") 
            //                                    : "",
            //           contractString = details ? (details.Contracts?.map((i) => `${i.ContractName}${nbsp}(${i.ContractNumber ?? "N/A"})`)
            //                                                         .join(", ") ?? "")
            //                                    : "",
            //           itemString = details ? (details.Items?.map((i) => `${i.ItemName ? i.ItemName.trim() : ""}${nbsp}(${i.ItemNumber ?? "N/A"})`)
            //                                                 .join(", ") ?? "")
            //                                : "";
                
            //     return { ...x,
            //             Customers: customerString,
            //             Items: itemString,
            //             Contracts: contractString };
            // }),
    
            productLineFloatTemplateColumns: (data: TemplateHistoryApiResultObject[]) => data.map(x => {
                const details = (x as ProductLineFloatTemplateHistoryApiResultObject),
                      nbsp = "\u00a0", //non-breaking space
                      customerString = details ? (details.Customers?.map((c) => `${c.CustomerName}${nbsp}(${c.CustomerId ?? "N/A"})`)
                                                                    .join(", ") ?? "") 
                                               : "",
                      productLineCategoryString = details ? (details.ProductLineCategories?.map((pc) => `${pc.ProductLineCategoryName}${nbsp}(${pc.ProductLineCategoryNumber ?? "N/A"})`)
                                                                                           .join(", ") ?? "") 
                                                          : "",
                      productLineString = details ? (details.ProductLines?.map((pl) => `${pl.ProductLineName}${nbsp}(${pl.ProductLineNumber ?? "N/A"})`)
                                                                          .join(", ") ?? "") 
                                                  : "",
                      contractString = details ? (details.Contracts?.map((i) => `${i.ContractName}${nbsp}(${i.ContractNumber ?? "N/A"})`)
                                                                    .join(", ") ?? "")
                                               : "";
                return { ...x, 
                        Customers: customerString, 
                        ProductLines: productLineString,
                        ProductLineCategories: productLineCategoryString,
                        Contracts: contractString };
            }) as HistoryDisplayTableData[],

    
            specialPricingExpirationTemplateColumns: (data: TemplateHistoryApiResultObject[]) => data.map(x => {
                const details = (x as SpecialPricingExpirationTemplateHistoryApiResultObject),
                      nbsp = "\u00a0", //non-breaking space
                      hasNewTerms = details ? !!details.HasNewTerms : false,
                      customerString = details ? (details.Customers?.map((c) => `${c.CustomerName}${nbsp}(${c.CustomerId})`)
                                                                    .join(", ") ?? "") 
                                               : "",
                      productLineCategoryString = details ? (details.ProductLineCategories?.map((pc) => `${pc.ProductLineCategoryName}${nbsp}(${pc.ProductLineCategoryNumber ?? "N/A"})`)
                                                                                           .join(", ") ?? "") 
                                                          : "",
                      productLineString = details ? (details.ProductLines?.map((pl) => `${pl.ProductLineName}${nbsp}(${pl.ProductLineNumber ?? "N/A"})`)
                                                                          .join(", ") ?? "") 
                                                  : "",
                      itemString = details ? (details.Items?.map((item) => `${item.ItemName ? item.ItemName.trim() : ""}${nbsp}(${item.ItemNumber ?? "N/A"})`)
                                                            .join(", ") ?? "") 
                                           : "",
                      contractString = details ? (details.Contracts?.map((i) => `${i.ContractName}${nbsp}(${i.ContractNumber ?? "N/A"})`)
                                                                    .join(", ") ?? "")
                                               : "";
                return { ...x, 
                        Customers: customerString, 
                        Items: itemString,
                        ProductLines: productLineString,
                        ProductLineCategories: productLineCategoryString,
                        Contracts: contractString,
                        HasNewTerms: hasNewTerms };
            }) as HistoryDisplayTableData[],
        },

        
        preprocess: (data: TemplateHistoryApiResultObject[]) => {
            const processed = data.map(x => {
                const output = x as HistoryDisplayTableData;
                output.CreatedDate = new Date(Date.parse(x.Created ?? ""));
                output.Status = getEnumKeys(TemplateStatus).find(s => TemplateStatus[s] === x.Status) ?? "N/A"
                return output;
            });
            return processed as HistoryDisplayTableData[];
        }
    };

    const defaults = {
        initialDataState: { take: 10, 
                            skip: 0 } as State,
        dateFilter: { logic: "and",
                    filters: [{ field: "CreatedDate",
                                operator: "gte",
                                value: new Date() }]
        } as CompositeFilterDescriptor,
        textExpandProps: {
            shouldRender: false,
            content: "",
            dataItemDetails: {
                fieldDisplayName: "",
                formatter: undefined
            }
        } as TextExpandPanelProps,

        //Custom filter list to remove the "is null" and "is not null" filters, since 
        //most users have no idea what that means.  The fact that the Kendo team thought they 
        //should be opt-out rather than opt-in is pretty wild.
        filters: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
            { text: 'grid.filterNotEqOperator', operator: 'neq' },
            { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
            { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
        ]
    };

    return {
        defaults,
        ...helpers
    };
};