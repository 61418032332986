export type SpecialPricingExpirationExcelTemplate = {
    CONTROLNUMBER: string | null,
    CUSTOMERNUMBER: string | null,
    CUSTOMERSHIPTO: string | null,
    CUSTOMERNAME: string | null,
    CUSTOMERADDRESS: string | null,
    PRODUCTLINENUMBER: string | null,
    PRODUCTLINEDESCRIPTION: string | null,
    PRODUCTCATEGORY: string | null,
    CATEGORYDESCRIPTION: string | null,
    EMPRESPONSIBLEPRICING: string | null,
    JOBTYPE: string | null,
    "SUPPLIERRESPONSIBLEY/N": string | null,
    CONTRACTNUMBER: string | null,
    CONTRACTNAME: string | null,
    ITEMNUMBER: string | null,
    ITEMDESCRIPTION: string | null,
    MANUFACTURERSNUMBER: string | null,
    PRICETYPE: string | null,
    PRICINGAMOUNT: string | null,
    PRICINGUOM: string | null,
    FLOATPERCENT: string | null,
    DELIVERYEFFECTIVEDATE: string | null,
    DELIVERYEXPIRATIONDATE: string | null,
    "EXTEND/DELETE/CANCEL/NEW": string | null,
    EXTENDDATE: string | null,
    COMMENTS: string | null,
    NEWPRICINGAMOUNT: string | null,
    NEWPRICEUOM: string | null,
    NEWCONTRACTNUMBER: string | null,
    NEWCONTRACTNAME: string | null,
    NEWSTARTDATE: string | null,
    NEWENDDATE: string | null,
    COMMENTS_1: string | null
}

export const SpecialPricingExpirationExcelTemplate_Default = {
    CONTROLNUMBER: "",
    CUSTOMERNUMBER: "",
    CUSTOMERSHIPTO: "",
    CUSTOMERNAME: "",
    CUSTOMERADDRESS: "",
    PRODUCTLINENUMBER: "",
    PRODUCTLINEDESCRIPTION: "",
    PRODUCTCATEGORY: "",
    CATEGORYDESCRIPTION: "",
    EMPRESPONSIBLEPRICING: "0",
    JOBTYPE: "0",
    "SUPPLIERRESPONSIBLEY/N": "N",
    CONTRACTNUMBER: "",
    CONTRACTNAME: "",
    ITEMNUMBER: "",
    ITEMDESCRIPTION: "",
    MANUFACTURERSNUMBER: "",
    PRICETYPE: "",
    PRICINGAMOUNT: "0",
    PRICINGUOM: "",
    FLOATPERCENT: "0",
    DELIVERYEFFECTIVEDATE: "",
    DELIVERYEXPIRATIONDATE: "",
    "EXTEND/DELETE/CANCEL/NEW": null,
    EXTENDDATE: "",
    COMMENTS: "",
    NEWPRICINGAMOUNT: "0",
    NEWPRICEUOM: "0",
    NEWCONTRACTNUMBER: "",
    NEWCONTRACTNAME: "",
    NEWSTARTDATE: "",
    NEWENDDATE: "",
    COMMENTS_1: ""
} as SpecialPricingExpirationExcelTemplate;
