import { ExcelTemplateTypes, 
         SpecialFixedTemplateSearchTypes, 
         SupplierTemplateSearchTypes, 
         ProductLineFloatTemplateSearchTypes,
         TemplateStatus, 
         SpecialPricingExpirationTemplateSearchTypes} from "enums";
import { useInOutTransition } from "hooks";
import { useEffect, useRef, useState, useCallback } from "react";
import { faMagnifyingGlassPlay } from "@fortawesome/pro-light-svg-icons";
import TemplateButton from "components/Buttons/TemplateButton";
import { SpecialFixedTemplateSearchOptions, 
         SupplierPricingTemplateSearchOptions, 
         ProductLineFloatTemplateSearchOptions, 
         SpecialPricingExpirationTemplateSearchOptions } from "components";
import "./TemplateHistorySearchBar.scss";
import CustomTextTag from "components/Text/CustomTextTag";

interface HistorySearchProps {
    shouldRender: boolean,
    className?: string,
    page: number,
    templateType: ExcelTemplateTypes,
    isSearching: boolean,
    onSearchStart: (type: string, page: number, terms: string, templateStatus: TemplateStatus) => Promise<void>
}

const getDefaultSearchType = (template: string | ExcelTemplateTypes) => {
    switch (template) {
        case ExcelTemplateTypes.Supplier:
            return SupplierTemplateSearchTypes.VendorNumber.toString();
        //case ExcelTemplateTypes.FloatItem:
        case ExcelTemplateTypes.FloatProductLine:
            return ProductLineFloatTemplateSearchTypes.CustomerId.toString();
        case ExcelTemplateTypes.Fixed:
            return SpecialFixedTemplateSearchTypes.CustomerId.toString();
        case ExcelTemplateTypes.Expiration:
            return SpecialPricingExpirationTemplateSearchTypes.CustomerId.toString();
        default:
            return "";
    }
}

export default function TemplateHistorySearchBar(props: HistorySearchProps) {
    const { shouldRender, className, page, templateType, isSearching, onSearchStart } = props;

    const [searchTerms, setSearchTerms] = useState<string>(""),
          [searchType, setSearchType] = useState<string>(""),
          [isTransitioningOptions, setIsTransitioningOptions] = useState<boolean>(false),
          displaySearchOptions = useRef<ExcelTemplateTypes>(templateType),
          isSupportedSearchType = templateType === ExcelTemplateTypes.Supplier || 
                                  templateType === ExcelTemplateTypes.FloatProductLine || 
                                  templateType === ExcelTemplateTypes.Fixed ||
                                  templateType === ExcelTemplateTypes.Expiration,
          optionsClass = templateType === ExcelTemplateTypes.Supplier ? "supplierTemplate" :
                         templateType === ExcelTemplateTypes.FloatItem ? "itemFloatTemplate" : 
                         templateType === ExcelTemplateTypes.FloatProductLine ? "productLineFloatTemplate" : 
                         templateType === ExcelTemplateTypes.Fixed ? "specialFixedTemplate" : 
                         templateType === ExcelTemplateTypes.Expiration ? "specialPricingExpirationTemplate" : "";

    const { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender);

    const handleKeydown = useCallback(async (key: string) => {
        return key.toLowerCase() === "enter" ? await onSearchStart(searchType, page, searchTerms, TemplateStatus.Any)
                                             : Promise.resolve();
    }, [searchType, page, searchTerms, onSearchStart]);

    //Transition timing handler for options selections
    useEffect(() => {
        let timer: NodeJS.Timeout,
            innerTimer: NodeJS.Timeout;
        if (templateType !== displaySearchOptions.current) {
            setIsTransitioningOptions(true);
            timer = setTimeout(() => {
                displaySearchOptions.current = templateType; 
                setSearchType(getDefaultSearchType(templateType));
                innerTimer = setTimeout(() => {
                    setIsTransitioningOptions(false);
                }, 225);
            }, 425);
        }
        return () => {
            clearTimeout(timer);
            clearTimeout(innerTimer);
        };
    }, [templateType, displaySearchOptions]);

    return (isNotTransitionedOut && 
        <div className={`${className ?? ""} historySearchBar ${shouldRender && isActive ? "" : "hidden"}`}>
            {isSupportedSearchType ? (
            <>
                <div className={`searchOptions ${optionsClass} ` + 
                                `${isTransitioningOptions ? "hidden" : ""}`}>
                    <label id="searchByLabel" 
                           className={`${isTransitioningOptions ? "hidden" : ""}`}>
                        {"New template search by..."}
                    </label>
                    <div className={`searchOptionsInputs ${optionsClass}`}>
                        {(templateType === ExcelTemplateTypes.Supplier && 
                            <SupplierPricingTemplateSearchOptions searchType={searchType} 
                                                                  updateHandler={setSearchType}
                                                                  selectedTemplateType={displaySearchOptions.current} />
                        )}
                        {(templateType === ExcelTemplateTypes.FloatProductLine && 
                            <ProductLineFloatTemplateSearchOptions searchType={searchType} 
                                                                   updateHandler={setSearchType}
                                                                   selectedTemplateType={displaySearchOptions.current} />
                        )}
                        {(templateType === ExcelTemplateTypes.Fixed && 
                            <SpecialFixedTemplateSearchOptions searchType={searchType} 
                                                               updateHandler={setSearchType}
                                                               selectedTemplateType={displaySearchOptions.current} />
                        )}
                        {(templateType === ExcelTemplateTypes.Expiration && 
                            <SpecialPricingExpirationTemplateSearchOptions searchType={searchType} 
                                                                          updateHandler={setSearchType}
                                                                          selectedTemplateType={displaySearchOptions.current} />
                        )}
                    </div>
                </div>

                <div className={`searchTextEntry ${isTransitioningOptions ? "hidden" : ""}`}>
                    <input id="searchTermInput"
                           type="text" 
                           value={searchTerms} 
                           placeholder="Search for..." 
                           onChange={(e) => setSearchTerms(e.target.value)}
                           onKeyDown={async (e) => await handleKeydown(e.key)}/>
                    <span className="searchHelpText">You can use commas to search for multiple values.</span>

                    <TemplateButton className="templateButtonSearchHistory" 
                                    text="SEARCH" 
                                    btnIcon={faMagnifyingGlassPlay}
                                    disabled={searchTerms === "" || isSearching}
                                    onClick={async () => await onSearchStart(searchType, page, searchTerms, TemplateStatus.Any)} />
                </div>
            </>
            ) : (
                <CustomTextTag text="COMING SOON" italic={true} className="comingSoonTag" />
            )}
        </div>
    );
}
