import { GridCellProps } from "@progress/kendo-react-grid";
import "./TemplateHistoryBooleanCell.scss";

interface CellProps {
    props: GridCellProps,
}

export default function TemplateHistoryBooleanCell(cellProps: CellProps) {
    const { props } = cellProps,
          status = props.dataItem["HasNewTerms"] ?? false,
          colorClass = status.toString(),
          statusText = colorClass.slice(0, 1).toUpperCase() + colorClass.slice(1);

    return (
        <td>
            <div className={`historyBooleanCell ${colorClass}`}>
                <span>{statusText}</span>    
            </div>
        </td>
    )
}
