export enum PriceChangeTypes {
    None = "Select price change type...",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    Other = "Other",
    MarketDriven = "Market Driven",
    NationalAccount = "National Account",
    NewItemSetup = "New Item Setup",
    Restructure = "Restructure"
}
