export const commonRegex = {
    dateFormats: {
        yyyymmdd: /^(?<year>\d{4})(?<month>\d{2})(?<day>\d{2})$/,
        mmddyyyy: /^(?<month>\d{1,2})\/?(?<day>\d{2})\/?(?<year>\d{4})$/
    },
    maxDecimalPrecision: (x: number) => { return new RegExp(`\\.\\d{${x + 1},}$`); },
    hasNonNumeric: /[^\d.-]+?/,
    hasNonPositiveNumeric: /[^\d.]+?/,
    hasSpaces: /\s/g,
    allNines: /^9{6,10}$/,
    fileExtension: /^(?<fileName>[^.]+)\.(?<extension>[^.]+)$/i
};
