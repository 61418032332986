import './WizardBar.scss';
import { useEffect, useState } from 'react';
import Step from "./Step";
import ProgressBar from "./ProgressBar";
import colors from "../../styles/ColorExports.module.scss";
import { faCheck, faX, faLoader } from "@fortawesome/pro-solid-svg-icons";
import { useExcelData } from 'components/Contexts/ExcelDataContext';

interface Props {
    show: boolean
    isUploading: boolean
    isValid: boolean
    isReviewing: boolean
    priceChangeType: string
}

export default function WizardBar({ show, isUploading, isValid, isReviewing, priceChangeType }: Props) {

    const [isVisible, setIsVisible] = useState(false);

    const { excelContext } = useExcelData(),
          { excelData } = excelContext;

    // Derived
    const uploadPrimaryColor = excelData && excelData.length > 0 ? colors.dotDarkGreen : undefined,
          uploadSecondaryColor = excelData && excelData.length > 0 ? colors.dotLightGreen : undefined,
          reviewPrimaryColor = isValid ? colors.dotDarkGreen : undefined,
          reviewSecondaryColor = isValid ? colors.dotLightGreen : undefined,
          priceChangePrimaryColor = priceChangeType ? colors.dotDarkGreen : undefined,
          priceChangeSecondaryColor = priceChangeType ? colors.dotLightGreen : undefined;

    // Determine the upload 1st step Icon based on conditions
    const getUploadStepIcon = () => {
        if (isUploading) 
            return faLoader; // Loader icon while uploading
        else if ((excelData && excelData.length > 0) || isValid === true) 
            return faCheck;  // Check icon when there's data
        else 
            return faX;      // X icon when no data and not uploading
    };

    // Determine the review 2nd step Icon based on conditions
    const getReviewStepIcon = () => {
        if (isReviewing) return faLoader;                       // Loader icon while submitting
        if (isValid === true) {                                  
            return faCheck;                                     // Check icon if the template is valid and review is finished
        } else {
            return faX;                                         // X icon if the template is invalid or needs partial submission approval (review needed)
        }
    };

    // Determine the type change 3rd step Icon based on conditions
    const getTypeChangeStepIcon = () => {
        return priceChangeType ? faCheck : faX;                                           
    };

    // Determine the supplier letter 4rth step Icon based on conditions
    const getSupplierLetterStepIcon = () => {
        return faX;                                             
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (show) {
            setIsVisible(true); // Start showing the banner
        } else {
            timeout = setTimeout(() => setIsVisible(false), 400); 
        }
        console.log("From Wizard: ", priceChangeType)
        return () => clearTimeout(timeout);
    }, [show, priceChangeType]);

    return (
        <div className={`wizardBanner ${show ? 'show' : ''} ${isVisible ? '' : 'hidden'}`}>
            <div className="wizardContentContainer">
                <Step
                    stepTitle={'Upload'}
                    stepCount={1}
                    progressBar={<ProgressBar approvePrimaryColor={uploadPrimaryColor} />}
                    stepIcon={getUploadStepIcon()}
                    approvePrimaryColor={uploadPrimaryColor}
                    approveSecondaryColor={uploadSecondaryColor}
                />
                <Step
                    stepTitle={'Review'}
                    stepCount={2}
                    progressBar={<ProgressBar approvePrimaryColor={reviewPrimaryColor} />}
                    stepIcon={getReviewStepIcon()}
                    approvePrimaryColor={reviewPrimaryColor}
                    approveSecondaryColor={reviewSecondaryColor}
                />
                <Step
                    stepTitle={'Price Change'}
                    stepCount={3}
                    progressBar={<ProgressBar />}
                    stepIcon={getTypeChangeStepIcon()}
                    approvePrimaryColor={priceChangePrimaryColor}
                    approveSecondaryColor={priceChangeSecondaryColor}
                />
                <Step
                    stepTitle={'Supplier Letter'}
                    stepCount={4}
                    stepIcon={getSupplierLetterStepIcon()}
                />
            </div>
        </div>
    );
}