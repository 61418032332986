import './Step.scss';
import React from 'react';
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    stepTitle: string
    stepCount: number
    stepIcon: IconDefinition
    progressBar?: React.ReactNode
    approvePrimaryColor?: string
    approveSecondaryColor?: string
}

export default function StepNavBar({ stepTitle, stepCount, stepIcon, progressBar, approvePrimaryColor, approveSecondaryColor }: Props) {

    const hasIcon = !!stepIcon;
    const hasProgressBar = !!progressBar;

    return (
        <div className={`stepContainer ${hasProgressBar ? 'withProgressBar' : 'withoutProgressBar'}`}>

            <div className="stepLabel">
                <div className="stepNumber" style={approvePrimaryColor ? { color: approvePrimaryColor } : undefined}>
                    {stepCount}
                </div>
                <div className="stepTitle" style={approvePrimaryColor ? { color: approvePrimaryColor } : undefined}>
                    {stepTitle}
                </div>
            </div>
            <div className="stepProgress">
                <div className="stepCircle"
                    style={{
                        ...(approvePrimaryColor && { color: approvePrimaryColor, borderColor: approvePrimaryColor }),
                        ...(approveSecondaryColor && { backgroundColor: approveSecondaryColor }),
                    }}
                >
                    {hasIcon ? <FontAwesomeIcon
                        className="iconSymbol"
                        icon={stepIcon}
                        size={'1x'}
                    /> :
                        ''
                    }
                </div>
                {progressBar }
            </div>
        </div>
    );
}